import { Button, Col, Form, FormInstance, InputRef, Popover, Row, Table, Tooltip } from "antd";
import i18n from "util/base/i18n";
import {
    Column,
    ColumnTypes,
    EditableCellProps,
    EditableRowProps,
    INewApportionmentList,
    TableData,
} from "../../../IApportionment";
import { Icon } from "@iconify/react";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import moment from "moment";
import { useWindowSize } from "hooks/useWindowSize";
import { NumericFormat } from "react-number-format";
import { useAccountAccountingOptions } from "hooks/useAccountAccountingOptions";
import Captcha from "components/captcha/Captcha";

export function NewApportionmentList({
    originTableData,
    destinationTableData,
    setDestinationTableData,
    selectedRowKeys,
    onChange,
    handleDelete,
    apportionmentType,
    apportionmentPeriod,
    onOpenTrasitoryAccountDrawer,
    width,
    costCenterOptions,
    isLoading,
    budgetDates,
    setIsOpenDeletePopover,
    isOpenDeletePopover,
}: INewApportionmentList) {
    const isDeleteBtnDisabled = selectedRowKeys.length === 0;
    const EditableContext = createContext<FormInstance<any> | null>(null);
    const windowSize = useWindowSize();
    const inputRefs = useRef([]);
    const { data: accountAccountingOptions } = useAccountAccountingOptions();

    const columns: Column[] = [
        {
            dataIndex: "costCenter",
            title: i18n.t("cost_center"),
            key: "costCenter",
            width: 200,
            fixed: "left",
            render: costCenter => {
                const costCenterItem = costCenterOptions?.find(item => item.value === costCenter.value);
                return costCenterItem?.label ? costCenterItem.label : "";
            }
        },
        {
            dataIndex: "account",
            title: i18n.t("account"),
            width: 200,
            key: "account",
            fixed: "left",
            render: account => {
                const accountItem = accountAccountingOptions?.find(item => item.value === account.value);
                return accountItem?.label ? accountItem.label : "";
            }
        },
        {
            dataIndex: ["businessUnit", "label"],
            title: i18n.t("business_unit"),
            width: 200,
            key: "businessUnit",
        },
        {
            dataIndex: "flexFields",
            title: i18n.t("flexField.modalTitle"),
            key: "flexFields",
            width: 200,
            render(
                flexFields: {
                    label: string;
                    value: number;
                }[]
            ) {
                return flexFields.length === 1 ? (
                    <p className="current-filter-value">{flexFields[0].label}</p>
                ) : flexFields.length > 1 ? (
                    <div className="gs-flex align-center">
                        <p className="current-filter-value">{flexFields[0].label}</p>
                        <Tooltip
                            color={"rgba(0,0,0,0.9"}
                            placement="right"
                            title={flexFields.map((item, index) => {
                                if (index === 0) return null;
                                return (
                                    <p key={item.value} style={{ margin: 0 }}>
                                        {item.label}
                                    </p>
                                );
                            })}
                        >
                            <span className="additional-items">{`+${flexFields.length - 1}`}</span>
                        </Tooltip>
                    </div>
                ) : (
                    <p className="current-filter-value"></p>
                );
            },
        },
    ];

    const destinationColumns: Column[] = [
        ...columns,
        ...apportionmentPeriod.map(
            (month) =>
            ({
                dataIndex: [month, "value"],
                title: moment(month, "YYYY-MM").format("MMM/YYYY"),
                key: month,
                editable: true,
                width: 100,
                align: "center",
                render: (value) =>
                    (value / 100).toLocaleString("pt-BR", {
                        style: "percent",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    }),
            } as Column)
        ),
    ];

    function handleReplicateValues(record, month, value) {
        const updatedTableData = [...destinationTableData];
        const monthIndex = apportionmentPeriod.indexOf(month);
        const index = updatedTableData.findIndex((item) => item.key === record.key);
        const data = updatedTableData.find((item) => item.key === record.key);

        Object.keys(data).forEach((key) => {
            const monthsToApply = apportionmentPeriod.slice(monthIndex + 1);
            if (monthsToApply.includes(key)) {
                data[key].value = value;
            }
        });
        updatedTableData.splice(index, 1, data);
        setDestinationTableData(updatedTableData);
    }

    const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
        const [form] = Form.useForm();
        return (
            <Form form={form} component={false}>
                <EditableContext.Provider value={form}>
                    <tr {...props} />
                </EditableContext.Provider>
            </Form>
        );
    };

    const EditableCell: React.FC<EditableCellProps> = ({
        title,
        editable,
        children,
        dataIndex,
        record,
        ...restProps
    }) => {
        const [editing, setEditing] = useState(false);
        const inputRef = useRef<InputRef>(null);
        const form = useContext(EditableContext)!;
        useEffect(() => {
            if (editing) {
                inputRef.current!.focus();
                inputRef.current!.select();
            }
        }, [editing]);

        if (!record) return <td {...restProps}>{children}</td>;

        const toggleEdit = () => {
            setEditing(!editing);
            form.setFieldsValue({ [dataIndex[0]]: { value: record[dataIndex[0]].value } });
            if (!inputRefs.current[destinationTableData.indexOf(record)]) {
                inputRefs.current[destinationTableData.indexOf(record)] = [];
            }
        };

        const save = async () => {
            try {
                const values: { month: { value: number; id: number } } = await form.validateFields();
                const month = Object.keys(values)[0];
                const value = Object.values(values)[0].value
                    ? Number(Object.values(values)[0].value.toString().replace(",", "."))
                    : 0;

                if (record[month].value === value) {
                    toggleEdit();
                    return;
                }
                const updatedTableData = [...destinationTableData];
                updatedTableData.find((item) => item.key === record.key)[month].value = value;
                setDestinationTableData(updatedTableData);

                toggleEdit();
            } catch (errInfo) {
                console.log("Save failed:", errInfo);
            }
        };
        let childNode = children;
        if (editable) {
            childNode = editing ? (
                <Form.Item style={{ margin: 0, padding: "2px 4px" }} name={dataIndex as string}>
                    <NumericFormat
                        onKeyDown={(event) => {
                            if (event.key === "Tab") {
                                event.preventDefault();
                                const nextCellIndex = moment(dataIndex[0]).month() + 1;
                                if (inputRefs.current[destinationTableData.indexOf(record)][nextCellIndex]) {
                                    inputRefs.current[destinationTableData.indexOf(record)][nextCellIndex].click();
                                    return;
                                }
                            }
                            if (event.key === "Enter") {
                                event.preventDefault();
                                save();
                                return;
                            }
                        }}
                        className="gs-input-numeric-format"
                        decimalSeparator=","
                        allowNegative={false}
                        getInputRef={inputRef}
                        onBlur={save}
                    />
                </Form.Item>
            ) : (
                <div className="gs-table-input-editable-cell">
                    <div
                        className="editable-cell-value-wrap"
                        onClick={toggleEdit}
                        ref={(el) => {
                            if (!inputRefs.current[destinationTableData.indexOf(record)]) {
                                inputRefs.current[destinationTableData.indexOf(record)] = [];
                            }
                            inputRefs.current[destinationTableData.indexOf(record)][moment(dataIndex[0]).month()] = el;
                        }}
                    >
                        {children}
                    </div>
                    <div className="gs-table-replicate-buttons-container right">
                        {dataIndex !== apportionmentPeriod[apportionmentPeriod.length - 1] && (
                            <Tooltip title={i18n.t<string>("replicate_to_next_months")}>
                                <Icon
                                    onClick={() => {
                                        handleReplicateValues(record, dataIndex[0], record[dataIndex[0]].value);
                                    }}
                                    icon="material-symbols:content-copy"
                                    style={{
                                        marginLeft: "auto",
                                        boxShadow: "none",
                                        color: "#A6A7A7",
                                        background: "transparent",
                                    }}
                                />
                            </Tooltip>
                        )}
                    </div>
                </div>
            );
        }

        return <td {...restProps}>{childNode}</td>;
    };

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    const cols = destinationColumns.map((col) => {
        if (!col?.editable || apportionmentType !== "PERIOD") {
            return col;
        }

        const columnDateKey = Array.isArray(col.dataIndex) ? col.dataIndex[0] : col.dataIndex;

        const [year, month] = columnDateKey.split("-").map(Number);
        const columnDate = new Date(year, month - 1);
        const realizedPeriod = budgetDates?.period?.find((p) => p.year === year);

        if (!realizedPeriod) {
            return col;
        }

        const realizedDate = realizedPeriod.expensesResourcesRealizedPeriod
            ? new Date(realizedPeriod.expensesResourcesRealizedPeriod)
            : null;

        const isEditable = !realizedDate || columnDate > realizedDate;

        return {
            ...col,
            onCell: (record: TableData) => ({
                record,
                editable: isEditable,
                dataIndex: col.dataIndex,
                title: col.title,
                align: "center",
                className: isEditable ? "" : "non-editable-cell",
            }),
        };
    });

    const rowSelection = { selectedRowKeys, onChange };

    const deletePopoverContent = (
        <div className="left-buttons-popover-container">
            <Button type="text" onClick={() => setIsOpenDeletePopover(false)}>
                {i18n.t<string>("cancel")}
            </Button>
            <Button className="gs-main-button delete-button" onClick={() =>
                handleDeleteAll()
            }>
                {i18n.t<string>("yes")}
            </Button>
        </div>
    );

    const handleDeleteAll = () => {
        setIsOpenDeletePopover(false);
        handleDelete();
    }

    const deletePopoverCaptchaContent = (
        <div className="left-buttons-popover-container">
            <Button type="text" onClick={() => setIsOpenDeletePopover(false)}>
                {i18n.t<string>("cancel")}
            </Button>
            <Captcha
                className="captcha-apportionment"
                tooltipTitleI18n={i18n.t<string>("delete_the_apportionment")}
                buttonTitleI18n={i18n.t<string>("delete")}
                onSuccessfulSubmit={handleDeleteAll}
                messageCaptcha={i18n.t<string>("delete_all_destinations_you_delete_apportionment")}
            />
        </div>
    );

    const isAllRowsSelected = selectedRowKeys.length === destinationTableData.length;

    return (
        <section className="apportionments-list-content" style={{ width: windowSize.width - width - 36 }}>
            {originTableData.length > 0 ? (
                <>
                    <header className="gs-flex flex-col align-start jc-center">
                        <h1>{i18n.t<string>("selected")}</h1>
                        <p>{i18n.t<string>("items_selected_for_apportionment")}</p>
                    </header>
                    <div className="apportionments-tables-container" style={{ width: windowSize.width - width - 29 }}>
                        <Row align="middle">
                            <Col span={2}>
                                <span className="gs-tag blue">{i18n.t("origin")}</span>
                            </Col>
                            <Col offset={17} span={5} className="gs-flex jc-end">
                                <Button
                                    style={{ marginRight: 10 }}
                                    type="default"
                                    onClick={() => onOpenTrasitoryAccountDrawer()}
                                >
                                    {i18n.t<string>("fill_transitory")}
                                </Button>
                            </Col>
                        </Row>
                        <Table
                            className="gs-table"
                            dataSource={originTableData}
                            columns={columns}
                            pagination={{ hideOnSinglePage: true }}
                        />
                        <>
                            <Row align="middle">
                                <Col span={2}>
                                    <span className="gs-tag blue">{i18n.t<string>("destination")}</span>
                                </Col>
                                <Col span={2}>
                                    <span className="gs-tag gray">
                                        {apportionmentType !== "HISTORIC"
                                            ? `${i18n.t(apportionmentType.toLowerCase())}`
                                            : `${i18n.t(apportionmentType)}`}
                                    </span>
                                </Col>

                                <Col offset={15} span={5}>
                                    <div className="gs-flex align-center jc-end">
                                        {isAllRowsSelected ? (
                                            <Popover
                                                open={isOpenDeletePopover}
                                                overlayClassName="currencies-popover"
                                                content={deletePopoverCaptchaContent}
                                                title={i18n.t<string>("delete_confirm_message")}
                                                trigger="click"
                                                placement="left"
                                            >
                                                <Button
                                                    type="text"
                                                    style={{
                                                        border: "none",
                                                        background: "transparent",
                                                        marginLeft: 20
                                                    }}
                                                    onClick={() => setIsOpenDeletePopover(true)}
                                                    disabled={isDeleteBtnDisabled}
                                                    icon={<Icon icon="fa6-solid:trash" />}
                                                />
                                            </Popover>
                                        ) : (
                                            <Popover
                                                open={isOpenDeletePopover}
                                                overlayClassName="currencies-popover"
                                                content={deletePopoverContent}
                                                title={i18n.t<string>("delete_confirm_message")}
                                                trigger="click"
                                                placement="left"
                                            >
                                                <Button
                                                    type="text"
                                                    style={{
                                                        border: "none",
                                                        background: "transparent",
                                                    }}
                                                    disabled={isDeleteBtnDisabled}
                                                    onClick={() => setIsOpenDeletePopover(true)}
                                                    icon={<Icon icon="fa6-solid:trash" />}
                                                />
                                            </Popover>
                                        )
                                        }
                                    </div>
                                </Col>
                            </Row>
                            <Table
                                className="gs-table"
                                dataSource={destinationTableData}
                                components={components}
                                scroll={{ x: windowSize.width - width, y: 600 }}
                                columns={cols as ColumnTypes}
                                pagination={{ hideOnSinglePage: true }}
                                rowSelection={rowSelection}
                                summary={(data: readonly TableData[]) => createGridSummary(data, apportionmentPeriod)}
                                loading={isLoading}
                                bordered
                            />
                        </>
                    </div>
                </>
            ) : (
                <div className="apportionment-empty-list-content">
                    <img src="/assets/images/image01.png" alt="apportionment" />
                    <h2>{i18n.t<string>("nothing_for_while")}</h2>
                    <p>{i18n.t<string>("start_apportionment")}</p>
                </div>
            )
            }
        </section >
    );
}

function createGridSummary(data: readonly TableData[], apportionmentPeriod: string[]) {
    const totals = data.reduce((acc, row) => {
        apportionmentPeriod.forEach((month) => {
            if (Object.keys(acc).includes(month)) {
                acc[month] = acc[month] + row[month].value;
            } else {
                Object.assign(acc, { [month]: row[month].value });
            }
        });
        return acc;
    }, {});
    const index = 1;

    return (
        <Table.Summary>
            <Table.Summary.Row style={{ background: "#FBE6E6" }}>
                <Table.Summary.Cell index={0} />
                <Table.Summary.Cell index={index} align="left">
                    {i18n.t("total")}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={index + 1} />
                <Table.Summary.Cell index={index + 2} />
                <Table.Summary.Cell index={index + 3} />
                {Object.values(totals).map((value: number, index) =>
                    value > 100 ? (
                        <Table.Summary.Cell
                            index={index + 5}
                            key={`value-${index}`}
                            align="center"
                            className={value > 100 ? "invalidTotalAmount" : ""}
                        >
                            <Tooltip title={i18n.t<string>("wrong_percentage_value")}>
                                {(value / 100).toLocaleString("pt-BR", {
                                    style: "percent",
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                })}
                            </Tooltip>
                        </Table.Summary.Cell>
                    ) : (
                        <Table.Summary.Cell
                            index={index + 5}
                            key={`value-${index}`}
                            align="center"
                            className={value > 100 ? "invalidTotalAmount" : ""}
                        >
                            {(value / 100).toLocaleString("pt-BR", {
                                style: "percent",
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}
                        </Table.Summary.Cell>
                    )
                )}
            </Table.Summary.Row>
        </Table.Summary>
    );
}