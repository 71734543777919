import { Icon } from "@iconify/react";
import { Alert, Button, Checkbox, Input, Popover, Spin, Tooltip } from "antd";
import { IImportModal } from "components/importExportMenu/IImportExportMenu";
import { ImportExportMenu } from "components/importExportMenu/ImportExportMenu";
import { usePermissionContext } from "context/PermissionContext";
import { BudgetPeriodDates } from "module/budget/pages/fixedExpense/IFixedExpense";
import moment from "moment";
import React, { useState } from "react";
import i18n from "util/base/i18n";
import { useOtherEventContext } from "../../../context/OtherEventContext";
import { IGridOperations } from "../../../IOtherEvents";
import { ModuleType } from "util/types/types";
import { PeriodBoardInfo } from "module/budget/components/PeriodBoardInfo/PeriodBoardInfo";

const CheckboxGroup = Checkbox.Group;
const { Search } = Input;

export function GridOperations({
    isOpenViewModePopover,
    setIsOpenViewModePopover,
    saveStatus,
    setOpenDistributionModal,
    isDistributionButtonDisabled,
    onViewModeCheckboxChange,
    viewModeSelected,
    setViewModeSelected,
    viewModeChecked,
    setViewModeChecked,
    handleExportGrid,
    searchValue,
    setSearchValue,
    budgetPeriodDates,
}: IGridOperations) {
	const { functionalityPermissions } = usePermissionContext();
	const [isImportModalOpen, setIsImportModalOpen] = useState(false);
	const importProps: IImportModal[] = [
		{
			templateUrl: "/planning/other-event/template?localeId={locale}&userId={user}&clientId={client}&scenarioId={scenario}&organizationId={organization}&businessUnitId={businessUnit}",
			importUrl: `/planning/other-event/import`,
			type: "excel",
		}
	]

    const viewModeOptions = [
        { value: "projectedValue", label: i18n.t("adjusted") },
        { value: "historicValue", label: i18n.t("historic") },
    ];

    const { period } = useOtherEventContext();

    const viewModePopoverContent = (
        <div className="grid-operations-viewMode-popover-container">
            <div style={{ padding: "0 10px" }}>
                <CheckboxGroup options={viewModeOptions} value={viewModeChecked} onChange={onViewModeCheckboxChange} />
            </div>
            <div className="grid-operations-viewMode-popover-footer">
                <Button
                    type="text"
                    onClick={() => {
                        setViewModeChecked(viewModeSelected);
                        setIsOpenViewModePopover(false);
                    }}
                >
                    {i18n.t<string>("cancel")}
                </Button>
                <Button
                    className="gs-main-button"
                    onClick={() => {
                        setViewModeSelected(viewModeChecked);
                        setIsOpenViewModePopover(false);
                    }}
                >
                    {i18n.t<string>("apply")}
                </Button>
            </div>
        </div>
    );

    const PeriodTag = ({
        budgetPeriodDates,
        period,
    }: {
        budgetPeriodDates: BudgetPeriodDates;
        period: moment.Moment[];
    }) => {
        if (!budgetPeriodDates.period || budgetPeriodDates.period.length === 0) return null;

        const periodsWithinSelectedRange = budgetPeriodDates.period.filter(
            (periodItem) => periodItem.year >= period[0].year() && periodItem.year <= period[1].year()
        );

        const maxPeriod = periodsWithinSelectedRange.reduce(
            (max, current) => (current.year > max.year ? current : max),
            periodsWithinSelectedRange[0]
        );

        if (!maxPeriod) return null;

        const realizedTag = maxPeriod.planningRealizedPeriod ? (
            <span key={`realized_${maxPeriod.year}`} className="gs-tag green" style={{ padding: "0" }}>
                {i18n.t("accomplished_until")}: {moment(maxPeriod.planningRealizedPeriod).format("MMM/YYYY")}
            </span>
        ) : null;

        return <React.Fragment key={`period_${maxPeriod.year}`}>{realizedTag}</React.Fragment>;
    };

    return (
        <div className="grid-operations-container">
			<div className="grid-operations-left-content">
                {functionalityPermissions.distribute ? (
                    <Tooltip title={i18n.t("human_resources.distribute_values")} placement="top">
                        <Button
                            className="grid-operation-button"
                            onClick={() => setOpenDistributionModal(true)}
                            style={{ color: isDistributionButtonDisabled ? "#A6A7A7" : "#5c5c5c" }}
                            disabled={isDistributionButtonDisabled}
                            icon={<Icon style={{ fontSize: 18 }} icon="lucide:align-horizontal-distribute-center" />}
                        />
                    </Tooltip>
                ) : null}
                {functionalityPermissions.vewmode ? (
                    <Popover
                        overlayClassName="currencies-popover"
                        content={viewModePopoverContent}
                        title={i18n.t("view")}
                        trigger="click"
                        placement="bottom"
                        open={isOpenViewModePopover}
                    >
                        <Tooltip
                            //color="rgba(0,0,0,0.85)"
                            title={i18n.t("fixed_expense_texts.view_mode")}
                            placement="top"
                        >
                            <Button
                                className="grid-operation-button"
                                onClick={() => setIsOpenViewModePopover(true)}
                                style={{ color: "#5c5c5c" }}
                                icon={
                                    viewModeSelected.length > 0 ? (
                                        <Icon
                                            icon="ic:round-remove-red-eye"
                                            style={{ fontSize: 20, marginBottom: -2, color: "#0065B3" }}
                                        />
                                    ) : (
                                        <Icon
                                            style={{ fontSize: 20, marginBottom: -2 }}
                                            icon="ic:outline-remove-red-eye"
                                        />
                                    )
                                }
                            />
                        </Tooltip>
                    </Popover>
                ) : null}
                <PeriodTag budgetPeriodDates={budgetPeriodDates} period={period} />
                <PeriodBoardInfo
                    budgetPeriodDates={budgetPeriodDates}
                    moduleType={ModuleType.PLANNING}
                    period={period}
                />
            </div>
            <div className="grid-operations-right-content">
                {!saveStatus.isSaving && (
                    <Alert
                        className="successfully-saved-budget-alert"
                        style={{
                            opacity: saveStatus.savedValue ? 1 : 0,
                        }}
                        message={i18n.t("changes_made_successfully")}
                        type="success"
                        showIcon
                        icon={<Icon icon="material-symbols:done-rounded" />}
                    />
                )}
                {saveStatus.isSaving && <Spin style={{ marginRight: 14 }} size={"small"} />}
                <Search value={searchValue} onChange={(e) => setSearchValue(e.target.value)} placeholder={i18n.t("search")} />
				{functionalityPermissions.import ||
                functionalityPermissions.refreshrhlinked ? (
                    <ImportExportMenu
                        buttonType="3dots"
                        exportGridData={handleExportGrid}
                        importModalOpen={isImportModalOpen}
                        setImportModalOpen={setIsImportModalOpen}
                        importProps={importProps}
                    />
                ) : null}
            </div>
        </div>
    );
}
