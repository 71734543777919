import { Checkbox, Col, Input, Row } from "antd";
import { CheckboxOptionType } from "antd/lib/checkbox/Group";
import { OptionSelect } from "module/budget/pages/collaborators/linkAccounts/ILinkAccounts";
import { FormulaItem } from "module/budget/pages/revenue/attributeParameterization/IAttributeParameterization";
import { useEffect, useState } from "react";
import i18n from "util/base/i18n";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import StringUtil from "util/StringUtil";
import { IOptionsListProps } from "../../../../IRegistrationSalesReports";
import { useNewReportContext } from "../../../NewReportContext";

interface Line {
    id: number;
    name: string;
    subLines: Line[];
    belongsLines?: Line[];
}

export default function OptionsList({ options = [] }: IOptionsListProps) {
    const [searchValue, setSearchValue] = useState("");
    const [defaultOptions, setDefaultOptions] = useState<OptionSelect[]>(options);
    const [filteredDefaultOptions, setFilteredDefaultOptions] = useState<OptionSelect[]>([]);
    const [optionsSelected, setOptionsSelected] = useState<CheckboxOptionType[]>([]);
    const [lines, setLines] = useState<Line[]>([]);
    const [isSelectedLevel, setIsSelectedLevel] = useState<Boolean>(null);

    const {
        report,
        isPlanningReport,
        formula,
        setFormula,
        selectedRowKey,
        selectedRowFormulas,
        selectedOptionsList,
        setSelectedOptionsList,
        updateOptionsRecursively,
    } = useNewReportContext();

    useEffect(() => {
        if (!isPlanningReport()) return;

        ServiceCaller.doRequest(
            {
                type: RequestType.GET,
                url: "/budget-report/report/get-option-formulas",
            },
            onLoadOptions
        );

        setFilteredDefaultOptions([]);
    }, []);

    useEffect(() => {
        if (!selectedRowFormulas.length) return;

        let defaultSelectedArr: string[] = [];
        selectedRowFormulas.forEach(({ attributeId, levelId, type }) => {
            if (isPlanningOption(type)) {
                defaultSelectedArr.push(`${type}-0`);
            } else {
                if (attributeId) {
                    defaultSelectedArr.push(`ATTRIBUTE-${attributeId}`);
                } else if (levelId) {
                    defaultSelectedArr.push(`LEVEL-${levelId}`);
                }
            }
        });
    }, [selectedRowFormulas]);

    useEffect(() => {
        setSelectedOptionsList([]);

        const levels = report.levels;

        const selectedLevel = levels.find((level) => level.id === selectedRowKey);

        if (!selectedLevel) return;

        const allRowsFormula: Line[] = [];

        levels.forEach((level) => {
            const newLine: Line = { id: level.id, name: `${level.externalCode} - ${level.description}`, subLines: [] };
        
            level.levelFormula.formulas.forEach((formula) => {
                if (formula.type === "LEVEL" && formula.levelId) {
                    const relatedLevel = levels.find((lvl) => lvl.id === formula.levelId);
                    if (relatedLevel) {
                        const subLine: Line = { id: level.id, name: `${relatedLevel.externalCode} - ${relatedLevel.description}`, subLines: [] };
                        newLine.subLines.push(subLine);
                    }
                }
            });
        
            const parentLine = allRowsFormula.find(
                (row) => row.name === level.externalCode
            );
            if (parentLine) {
                addSubLine(parentLine, newLine);
            } else {
                allRowsFormula.push(newLine);
            }
        });
        
        const initialLines = updateBelongsLines(allRowsFormula);
        setLines(initialLines);
        validateIsSelectedLevel();
    }, [selectedRowKey, defaultOptions, report.levels, updateOptionsRecursively]);
    
    useEffect(() => {
        if (!formula.length) return;

        const linesFormated: Line[] = [...lines]

        formula.forEach((formula) => {
            if (formula.type === "attribute") {
                const formulaName: string = formula.content.name?.split(":").pop().trim();

                if (!formulaName) return;

                const selectedLevel = report.levels.find((level) => level.id === selectedRowKey);
                const externalCode = `${selectedLevel.externalCode} - ${selectedLevel.description}`;
                const currentLine = linesFormated.find((line) => line.name === externalCode);

                const lineToAdd = lines.find((line) => line.name === formulaName);

                if (currentLine && lineToAdd) {
                    currentLine.subLines = currentLine.subLines.concat({ ...lineToAdd });
                }
            }
        });

        setLines(updateBelongsLines(linesFormated));
        validateIsSelectedLevel();
    }, [formula]);

    useEffect(() => {
        if (!selectedRowKey || !lines.length) return;

        const selectedLevel = report.levels.find((level) => level.id === selectedRowKey);

        if (!selectedLevel) return;

        const updatedOptions = new Set(defaultOptions.map((option) => option.value));

        const relatedLevels: number[] = [];

        const updateRecursiveOptions = (externalCode: string) => {
            const currentLine = lines.find((line) => line.name === externalCode);

            if (!currentLine) return;

            const hasRelated = relatedLevels.find((level) => level === selectedRowKey)
            if (hasRelated && currentLine.id === selectedRowKey) return;

            currentLine.belongsLines?.forEach((line) => {
                const optionToRemove = defaultOptions.find((option) => option.label === line.name);
                if (optionToRemove) {
                    if (optionToRemove.value !== updateOptionsRecursively) {
                        updatedOptions.delete(optionToRemove.value);
                        relatedLevels.push(Number(optionToRemove.value.split("-").pop()));
                    }
                }

                if (currentLine.belongsLines?.length) {
                    const lineRelated = currentLine.belongsLines.find(belongs => belongs.name === line.name)
                    if (lineRelated) {
                        updateRecursiveOptions(lineRelated.name);
                    }
                }
            });

            currentLine.subLines.forEach((subLine) => {
                const optionToRemove = defaultOptions.find((option) => option.label === subLine.name);
                if (optionToRemove) {
                    if (optionToRemove.value !== updateOptionsRecursively) {
                        updatedOptions.delete(optionToRemove.value);
                        relatedLevels.push(Number(optionToRemove.value.split("-").pop()))
                    }
                }

                if (subLine.subLines.length) {
                    updateRecursiveOptions(subLine.name);
                }
            });
        };

        updateRecursiveOptions(`${selectedLevel.externalCode} - ${selectedLevel.description}`);

        const filteredOptions = defaultOptions.filter((option) => updatedOptions.has(option.value));
        const filteredDefaultOptions = filteredOptions.filter((option) => {
            const [preffix] = option.value.split("-");

            return isSelectedLevel !== null ? (isSelectedLevel ? preffix === "LEVEL" : preffix !== "LEVEL") : true;
        })

        setFilteredDefaultOptions(filteredDefaultOptions);
    }, [lines]);
    
    useEffect(() => {
        if (!optionsSelected) return;
        addAttributeToFormula(optionsSelected);
    }, [optionsSelected]);

    useEffect(() => {
        let isSelectedLevel = false;
        selectedOptionsList.forEach((item: string) => {
            const [prefix] = item.split("-");
    
            if (!isSelectedLevel && prefix === "LEVEL") {
                isSelectedLevel = true;
            }
        });
    
        const selectedOptions = defaultOptions.filter(({ value }) => selectedOptionsList.includes(value));
        selectedOptions.filter((item) => {
            const [prefix] = item.value.split("-");
            return isSelectedLevel !== null ? (isSelectedLevel ? prefix === "LEVEL" : false) : null;
        })

        setIsSelectedLevel(formula.length !== 0 ? isSelectedLevel : null);

        addAttributeToFormula(selectedOptions);
    }, [selectedOptionsList]);

    const validateIsSelectedLevel = () => {
        if (formula.length > 0) {
            let isSelectedLevel = false;
            formula.forEach(operation => {
                if (!isSelectedLevel) {
                    if (operation.type === "operator") return;

                    if (operation.key.split("-")) {
                        const a = operation.key.split("-")[0];
                        isSelectedLevel = a === "LEVEL";
                    }
                }
            })

            setIsSelectedLevel(isSelectedLevel);
        } else {
            setIsSelectedLevel(null);
        }
    }

    const validateAddition = (currentLine: Line, newLine: Line): boolean => {
        const recursivelyContains = (line: Line, target: Line): boolean => {
            if (line.subLines.some((sub) => sub.name === target.name)) {
                return true;
            }
            return line.subLines.some((sub) => recursivelyContains(sub, target));
        };

        if (currentLine.name === newLine.name || recursivelyContains(newLine, currentLine)) {
            console.log(`Error: ${newLine.name} cannot be added to ${currentLine.name}`);
            return false;
        }

        for (const subLine of newLine.subLines) {
            if (
                currentLine.name === subLine.name ||
                recursivelyContains(currentLine, subLine)
            ) {
                console.log(`Error: ${newLine.name} contains sub-lines already in ${currentLine.name}`);
                return false;
            }
        }

        if (
            newLine.subLines.length > 0 &&
            newLine.subLines.every((sub) =>
                currentLine.subLines.some((existingSub) => existingSub.name === sub.name)
            )
        ) {
            console.log(`Error: ${newLine.name} and its sub-lines already exist in ${currentLine.name}`);
            return false;
        }

        return true;
    };

    const addSubLine = (currentLine: Line, newLine: Line): void => {
        if (validateAddition(currentLine, newLine)) {
            setLines((prevLines) =>
                prevLines.map((line) =>
                    line.name === currentLine.name
                        ? { ...line, subLines: [...line.subLines, newLine] }
                        : line
                )
            );
        }
    };

    const updateBelongsLines = (allLines: Line[]): Line[] => {
        const lineMap = new Map<string, Line>();

        allLines.forEach((line) => lineMap.set(line.name, line));

        allLines.forEach((line) => {
            line.subLines.forEach((subLine) => {
                const targetLine = lineMap.get(subLine.name);
                if (targetLine) {
                    targetLine.belongsLines = [...(targetLine.belongsLines || []), line];
                }
            });
        });

        return allLines;
    };

    function addAttributeToFormula(attributes: CheckboxOptionType[]) {
        const formulasAttribute: FormulaItem[] = formula.filter(({ type }) => type === "attribute");
        if (!!attributes.length) {
            const [attributeToAdd]: CheckboxOptionType[] = attributes.filter(({ value }) => {
                return !formulasAttribute.map(({ key }) => key).includes(value);
            });
            
            if (!attributeToAdd) {
                setFormula([
                    ...formula,
                ]);
                setOptionsSelected([]);
                return;
            }

            const content = {
                name: attributeToAdd.label,
                id: attributeToAdd.value,
            };

            setFormula([
                ...formula,
                {
                    type: "attribute",
                    content,
                    key: attributeToAdd.value,
                },
            ]);
        }
    }

    const filteredOptionsToCheckboxOption: CheckboxOptionType[] = filteredDefaultOptions
        .map(({ label, value }) => {
            const labelFormatted: string = value.startsWith("LEVEL") ? `${i18n.t("report")}: ${label}` : label;

            const style: React.CSSProperties = isPlanningOption(value.split("-")[0]) ? { background: "#F1F2F3" } : {};

            return {
                value,
                label: labelFormatted,
                style,
            };
        })
        .filter(
            ({ label, value }) =>
                filterOption(value) &&
                StringUtil.getWithoutSpecials(label)?.includes(StringUtil.getWithoutSpecials(searchValue))
        );

    function filterOption(value: string): boolean {
        return value !== `LEVEL-${selectedRowKey}`;
    }

    function isPlanningOption(value: string): boolean {
        return ["MOVEMENT", "OPENING_BALANCE", "FINAL_BALANCE", "CREDIT", "DEBIT"].includes(value);
    }

    function onLoadOptions(planningOptions: any[]) {
        setDefaultOptions((state) => {
            const newPlanningOptions = planningOptions.map(({ name }) => {
                const translatedName: string = i18n.t(`new_sales_report.options_formula.${name}`);
                return {
                    value: `${name}-0`,
                    label: translatedName,
                };
            });
            return [...newPlanningOptions, ...state];
        });
    }

    return (
        <div className="options-list">
            <Row>{i18n.t<string>("list_of_options")}</Row>
            <div className="options-list-content">
                <Row>
                    <Col span={24}>
                        <Input.Search
                            className="search-business-unit"
                            placeholder={i18n.t<string>("search_values")}
                            value={searchValue}
                            onChange={({ target: { value } }) => setSearchValue(value)}
                        />
                        <Checkbox.Group
                            value={selectedOptionsList}
                            onChange={(listChecked) => {
                                setSelectedOptionsList([...selectedOptionsList, ...listChecked] as string[]);
                                setOptionsSelected(
                                    listChecked.map((checkedId) => ({
                                        label: filteredOptionsToCheckboxOption.find(({ value }) => value === checkedId)?.label ?? "",
                                        value: checkedId,
                                    }))
                                );
                            }}
                            options={filteredOptionsToCheckboxOption}
                        />
                        <span className="footer">
                            {filteredOptionsToCheckboxOption?.length} {i18n.t<string>("items")}
                        </span>
                    </Col>
                </Row>
            </div>
        </div>
    );
}
