import { FormInstance } from "antd";
import { TableRowSelection } from "antd/lib/table/interface";

export interface IDistributionRuleContext {
	tableData: IDistributionRuleData[];
	setTableData: React.Dispatch<React.SetStateAction<IDistributionRuleData[]>>;
	isTableDataLoading: boolean;
	setIsTableDataLoading: React.Dispatch<React.SetStateAction<boolean>>;
	isModalOpen: boolean;
	setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
	isSaving: boolean;
	setIsSaving: React.Dispatch<React.SetStateAction<boolean>>;
	ruleForEdit: IDistributionRuleData;
	setRuleForEdit: React.Dispatch<React.SetStateAction<IDistributionRuleData>>;
	ruleFilterOptions: IOptionRuleFilter[];
	setRuleFilterOptions: React.Dispatch<React.SetStateAction<IOptionRuleFilter[]>>;
	selectedTab: "filter" | "result";
	setSelectedTab: React.Dispatch<React.SetStateAction<string>>;
	isOptionsLoading: boolean;
	setIsOptionsLoading: React.Dispatch<React.SetStateAction<boolean>>;
	filtersForm: FormInstance<IFilterFormValues>;
	ruleConditionFilter: IDistributionRuleFilter[];
	setRuleConditionFilter: React.Dispatch<React.SetStateAction<IDistributionRuleFilter[]>>;
	ruleConditionResult: IDistributionRuleResultFilter[];
	setRuleConditionResult: React.Dispatch<React.SetStateAction<IDistributionRuleResultFilter[]>>;
	getTableData: () => Promise<void>;
	rowSelection: TableRowSelection<IDistributionRuleData>;
	selectedRowKeys: number[];
	hasEdited: boolean;
	setHasEdited: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface IDistributionRuleData {
	businessUnitId: number;
	organizationId: number;
	updatedByUserId: number;
	id?: number;
	scenarioId: number;
	lastUserChangeName?: string;
	description: string;
	order: number;
	planned: boolean;
	accomplished: boolean;
	percentageValue: number;
	multiplier: number;
	startDate: number | string;
	endDate: number | string;
	lastUserChangeDate?: string;
	filters: IDistributionRuleFilter[];
	results: IDistributionRuleResultFilter[];
}

export interface ICommomDistributionRuleFilter {
	distributionRuleId?: number;
	filterOrder: number;
	fieldId: number;
	fieldValueId: number;
	operation: OperationType;
	fieldValueName: string;
	fieldName: string;
	type?: FilterType | FilterResultType;
}

export interface IDistributionRuleFilter extends ICommomDistributionRuleFilter {
	filter: FilterType
}

export enum OperationType {
	PLUS = "PLUS",
	LESS = "LESS",
	EQUALS = "EQUALS",
	NOT_EQUALS = "NOT_EQUALS"
}

export enum FilterType {
	FLEX_FIELD = "FLEX_FIELD",
	COST_CENTER = "COST_CENTER",
	ACCOUNTING_ACCOUNT = "ACCOUNTING_ACCOUNT",
	ACCOUNT_LEVEL = "ACCOUNT_LEVEL",
	COST_CENTER_LEVEL = "COST_CENTER_LEVEL",
	FLEX_FIELD_DETAIL = "FLEX_FIELD_DETAIL"
}

export enum FilterResultType {
	FLEX_FIELD = "FLEX_FIELD",
	COST_CENTER = "COST_CENTER",
	ACCOUNTING_ACCOUNT = "ACCOUNTING_ACCOUNT",
}

export interface IDistributionRuleResultFilter extends ICommomDistributionRuleFilter {
	result: FilterResultType
}

export interface IOptionRuleFilter {
	value: string;
	label: string;
	filter: FilterType;
}

export interface IOptionRuleResult {
	value: string;
	label: string;
	filter: FilterResultType;
}

export interface IFilterFormValues {
	fieldId: string;
	fieldValueId: number | number[];
	operation: OperationType;
}

export interface ICalculateRulesFormValues {
	startPeriod: string;
	endPeriod: string;
	planned: boolean;
	accomplished: boolean;
}

export interface IAccountNomenclatureLink {
	nomenclature: string;
	id: number;
}

export interface IAccountLevelResponse {
	[id: number]: string;
}

export interface IDistributionRuleForm {
	description: string;
	order: number;
	planned: boolean;
	accomplished: boolean;
	startDate: moment.Moment;
	endDate: moment.Moment;
	percentageValue: number;
	multiplier: number;
}