import { ApportionmentType } from "./IApportionment"

export const apportionmentOptions = [
    {
        label: 'all',
        value: 4,
    },
    {
        label: 'period',
        value: 1,
    },
    {
        label: 'headcount',
        value: 2,
    },
    // {
    //     label: 'historic',
    //     value: 3,
    // },
]

export const type = {
    1: ApportionmentType.PERIOD,
    2: ApportionmentType.HEADCOUNT,
    3: ApportionmentType.HISTORIC,
    4: ApportionmentType.ALL
} 