import { useState } from "react";
import { InvestmentAccountingModalProps, TransferData, ValueForm } from "../IInvestmentAccounting";
import { Button, Cascader, Col, Form, Input, InputNumber, Modal, Row, Select } from "antd";
import i18n from "util/base/i18n";
import { Notification } from "components/notification/Notification";
import { ImageBox } from "components/imageBox/ImageBox";
import { Icon } from "@iconify/react";
import { validateFormField } from "util/functions/validateFormField";
import Transfer from "./Transfer/Transfer";

export default function InvestmentAccountingModal({
    isModalVisible,
    isNewAccounting,
    handleSubmit,
    handleCancel,
    form,
    modalList,
    setModalList,
    handleSaveAccount,
    tableData,
    selectedRowKeys,
    accountingAccountList,
    isFetchingAccountingAccountList,
    devaluationList,
    isFetchingDevaluationList,
    formattedDevaluation,
    costCenterList,
    setCostCenterList,
    isFetchingCostCenterList,
    setSelectedCostCenters,
    selectedCostCenters,
    showTransfer,
    setShowTransfer,
}: InvestmentAccountingModalProps) {

    const [editDisebled, setEditDisebled] = useState(false);

    const modalTitle = isNewAccounting
        ? i18n.t("investment_accounting.new_accounting_registration")
        : i18n.t("investment_accounting.edit_accounting_register");

    function handleEditListItem(accounting: ValueForm) {
        setEditDisebled(true);
        form.setFieldsValue({
            externalCode: accounting.externalCode,
            description: accounting.description,
            devaluationAccountId: accounting.devaluationAccountId,
            immobilizedAccountId: accounting.immobilizedAccountId,
            immobilizedProgressAccountId: accounting.immobilizedProgressAccountId,
            costCenterId: accounting.costCenterId,
            devaluationRate: accounting.devaluationRate
        });
        handleDeleteListItem(accounting.externalCode);
    }

    function handleDeleteListItem(externalCode) {
        const accounting = [...modalList];
        const i = accounting.findIndex((acc) => (acc.externalCode === externalCode));
        accounting.splice(i, 1);
        setModalList(accounting);
    }

    function findAccountLabel(accountID) {
        const immobilizedAccount = accountingAccountList.find(account => account.value === accountID);
        return immobilizedAccount ? immobilizedAccount.label : null;
    }
    
    function handleShowTransfer() {
        setShowTransfer(!showTransfer);
    }

    return (
        <Modal
            width={1100}
            title={modalTitle}
            visible={isModalVisible}
            okButtonProps={modalList.length > 0 || !isNewAccounting && !showTransfer ? { htmlType: "submit", form: "new-account-form" } : { disabled: true }}
            onCancel={() => {
                handleCancel()
            }}
            className="gs-modal account-registration-modal investment-accounting-modal"
            cancelText={i18n.t<string>("cancel")}
            okText={i18n.t<string>("save")}
            onOk={() => {
                if (isNewAccounting) {
                    if (modalList.length > 0) {
                        handleSaveAccount(modalList);
                    } else {
                        Notification({
                            type: 'warning',
                            message: i18n.t("no_items_added_to_list")
                        })
                    }
                }
            }}
        >
            <Form
                form={form}
                name="new-account-form"
                className={isNewAccounting && !showTransfer ? "form-new-account" : ""}
                onFinish={(data) => {
                    setEditDisebled(false)
                    handleSubmit(data)
                }}
                layout="vertical"
                requiredMark={false}
            >
            {!showTransfer ?
                <div>
                    <Row gutter={20}>
                        <Col span={8}>
                            <Form.Item
                                name="externalCode"
                                label={i18n.t<string>("external_code")}
                                rules={[
                                    { required: true, message: i18n.t<string>("required_field") },
                                    () => ({
                                        validator(_, value: string) {
                                            return validateFormField(value, 'externalCode', tableData, modalList, i18n.t<string>("the_external_code_field_cannot_repeat"), selectedRowKeys[0]);
                                        },
                                    })
                                ]}
                            >
                                <Input placeholder={i18n.t<string>("type_here")} disabled={!isNewAccounting ?? false} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="description"
                                label={i18n.t<string>("description")}
                                rules={[
                                    { required: true, message: i18n.t<string>("required_field") },
                                ]}
                            >
                                <Input placeholder={i18n.t<string>("type_here")} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="devaluationAccountId"
                                label={i18n.t("investment_accounting.depreciation")}
                                rules={[{ required: true, message: i18n.t<string>("required_field") }]}
                            >
                                <Select
                                    loading={isFetchingDevaluationList}
                                    options={devaluationList}
                                    showSearch={true}
                                    optionFilterProp={"children"}
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    placeholder={i18n.t<string>("select")}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={8}>
                            <Form.Item
                                name="immobilizedAccountId"
                                label={i18n.t("investment_accounting.immobilized")}
                                rules={[{ required: true, message: i18n.t<string>("required_field") }]}
                            >
                                <Select
                                    loading={isFetchingAccountingAccountList}
                                    options={accountingAccountList}
                                    showSearch={true}
                                    optionFilterProp={"children"}
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    placeholder={i18n.t<string>("select")}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="immobilizedProgressAccountId"
                                label={i18n.t("investment_accounting.immobilized_progress")}
                            >
                                <Select
                                    loading={isFetchingAccountingAccountList}
                                    options={accountingAccountList}
                                    showSearch={true}
                                    optionFilterProp={"children"}
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    placeholder={i18n.t<string>("select")}
                                    allowClear
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="devaluationRate"
                                label={`${i18n.t<string>("rate")} (%)`}
                                rules={[
                                    { required: true, message: i18n.t<string>("required_field") },
                                    () => ({
                                        validator(_, value: any) {
                                            if (value > 100) {
                                                return Promise.reject(
                                                    new Error(i18n.t<string>("error_rate_max"))
                                                );
                                            }

                                            if (value === 0) {
                                                return Promise.reject(
                                                    new Error(i18n.t<string>("error_rate_min"))
                                                );
                                            }

                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <InputNumber
                                    onChange={value => {
                                        if (Number(value) >= 0) {
                                            return (new Intl.NumberFormat(i18n.language.replace("_", "-"), {
                                                notation: "compact",
                                            }).format(Number(value)))
                                        }
                                    }}
                                    controls={false}
                                    decimalSeparator={","}
                                    style={{ width: "100%" }}
                                    precision={2}
                                    placeholder={i18n.t<string>("type_here")}
                                    min={0}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8} style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <div onClick={handleShowTransfer}>
                                Vincular Centro de Custo
                                {selectedCostCenters.length > 0 && <Icon icon="material-symbols:check-circle-outline-rounded"  style={{color: "#279033", marginLeft: "0.5rem"}} />}
                                <Icon icon="iconamoon:arrow-down-2"  style={{color: "#424242", marginLeft: "0.5rem"}} />
                            </div>
                        </Col>
                    </Row>
                </div>
                :
                <div>
                     <div onClick={handleShowTransfer} style={{marginBottom: "1rem"}}>
                        Vincular Centro de Custo
                        <Icon icon="iconamoon:arrow-up-2"  style={{color: "#424242", marginLeft: "1rem"}}/>
                    </div>
                    <Row>
                        <Transfer
                            leftTableName={"Vínculo de centro de Custo"}
                            rightTableName={"Centros de Custo vinculados"}
                            tableData={costCenterList}
                            selectedData={selectedCostCenters}
                            setSelectedData={setSelectedCostCenters}
                            setShowTransfer={setShowTransfer}
                        />
                    </Row>
                </div>
            }
                {isNewAccounting && !showTransfer &&
                    <Button
                        style={{ marginTop: -10, marginLeft: "auto" }}
                        type="default"
                        htmlType="submit"
                        className="gs-secondary-button"
                        icon={<Icon icon="akar-icons:circle-plus-fill" />}
                    >
                        {i18n.t('addToList')}
                    </Button>}
            </Form>
            {isNewAccounting && !showTransfer &&
                (modalList.length > 0 ?
                    <>
                        <div className="new-account-table-head">
                            <Row align="middle" gutter={2}>
                                <Col span={2}>{i18n.t<string>("code")}</Col>
                                <Col span={2}>{i18n.t<string>("description")}</Col>
                                <Col span={4}>{i18n.t("investment_accounting.depreciation")}</Col>
                                <Col span={4}>{i18n.t("investment_accounting.immobilized")}</Col>
                                <Col span={5}>{i18n.t("investment_accounting.immobilized_progress")}</Col>
                                <Col span={3}> {i18n.t("cost_center")}</Col>
                                <Col span={2}>{`${i18n.t<string>("rate")} (%)`}</Col>
                                <Col span={1} />
                                <Col span={1} />
                            </Row>
                        </div>
                        <div className="new-account-table-body">
                            {modalList.map((accounting) => {
                                return (
                                    <Row key={accounting.externalCode} align="middle" gutter={2}>
                                        <Col span={2}>{accounting.externalCode}</Col>
                                        <Col span={2}>{accounting.description}</Col>
                                        <Col span={4}>{(() => {
                                            const accountOption = devaluationList.find(account => account.value === accounting.devaluationAccountId);
                                            return accounting ? accountOption.label : null;
                                        })()}</Col>
                                        <Col span={4}>{findAccountLabel(accounting.immobilizedAccountId)}</Col>
                                        <Col span={5}>{findAccountLabel(accounting.immobilizedProgressAccountId)}</Col>
                                        <Col span={3}>{selectedCostCenters.length > 0 && <Icon icon="material-symbols:check-circle-outline-rounded"  style={{color: "#279033"}}/>}</Col>
                                        <Col span={2}>{formattedDevaluation(accounting.devaluationRate)}</Col>
                                        <Col span={1}>
                                            <Button
                                                disabled={editDisebled}
                                                onClick={() => handleEditListItem(accounting)}
                                                icon={<Icon className="edit-button" icon="material-symbols:edit-sharp" />}
                                            />
                                        </Col>
                                        <Col span={1}>
                                            <Icon
                                                className="delete-icon"
                                                onClick={() => handleDeleteListItem(accounting.externalCode)}
                                                icon="fa6-solid:trash"
                                            />
                                        </Col>
                                    </Row>
                                );
                            })}
                        </div>
                    </>
                    :
                    <ImageBox />
                )
            }
        </Modal>
    )
}