import { Button, Carousel } from "antd";
import { carouselDefaultProps } from "components/carousel/ICarousel";
import { Filter } from "components/filter/Filter";
import { FilterSkeleton } from "components/skeleton/filterSkeleton/FilterSkeleton";
import { useEffect, useState } from "react";
import i18n from "util/base/i18n";
import { apportionmentOptions, type } from "../../../constants";
import { useWindowSize } from "hooks/useWindowSize";
import { ApportionmentType, IFilters } from "../../../IApportionment";
import { Options } from "util/types/types";
import moment from "moment";
import { onChangeFlexFieldFilter } from "util/functions/onChangeFlexFieldFilter";
import { ImportExportMenu } from "components/importExportMenu/ImportExportMenu";
import { IImportModal } from "components/importExportMenu/IImportExportMenu";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";

export function Filters({
    businessUnitOptions,
    costCenterOptions,
    flexFieldsOriginOptions,
    setFlexFieldsOriginOptions,
    fetchApportionments,
    openCalendarPeriod,
    year,
    setYear
}: IFilters) {
    const [apportionmentType, setApportionmentType] = useState<Options[]>([])
    const [businessUnit, setBusinessUnit] = useState<Options[]>([])
    const [costCenter, setCostCenter] = useState<Options[]>([])
    const [account, setAccount] = useState<Options[]>([])
    const [flexFieldStringKey, setFlexFieldStringKey] = useState(Array(30).join(':'))
    const [isFetchingFlexFields, setIsFetchingFlexFields] = useState(false)
    const [filtersPerRow, setFiltersPerRow] = useState(5)
    const windowSize = useWindowSize();
    const [optionsAccounting, setOptionsAccounting] = useState<Options[]>([])

    useEffect(() => {
        const updatedFiltersPerRow = Math.floor((windowSize.width - 288) / 195)
        setFiltersPerRow(updatedFiltersPerRow)
    }, [windowSize])

    useEffect(() => {
        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: `/budget-base/accounting-account?locale={locale}&client={client}&user={user}&organization={organization}&bu={businessUnit}`,
        }, data => {
            setOptionsAccounting(data)
        })
    }, [])

    const importProps: IImportModal[] = [
        {
            importUrl: "/expenses/apportionment/import",
            templateUrl: "/expenses/apportionment/template?user={user}&client={client}&locale={locale}",
            type: "excel",
            title: i18n.t("apportionment_import"),
            hasImportOption: true,
        }
    ]
    return (
        <div className="apportionment-filters-container">
            {isFetchingFlexFields ?
                <FilterSkeleton
                    direction='row'
                    active
                    amount={4}
                    gap={10}
                    cardClassName='filter-card-skeleton'
                />
                :
                <Carousel
                    className="gs-carousel carousel-infos"
                    {...carouselDefaultProps}
                    slidesPerRow={filtersPerRow}
                    style={{ width: "calc(90% - 25px)", margin: "0 12px" }}
                >
                    <Filter
                        id="period-filter"
                        value={year}
                        setValue={(value) => {
                            setYear(value)
                        }}
                        title={i18n.t("year")}
                        type="datepicker"

                        data={[]}
                        datepicker={{
                            format: "YYYY",
                            picker: "year",
                            allowClear: false,
                            defaultToday: true,
                            period: [openCalendarPeriod]
                        }}
                        buttonStyles={{ cursor: 'default' }}
                    />
                    <Filter
                        id="period-filter"
                        value={apportionmentType}
                        title={i18n.t("Critério de rateio")}
                        type="radio"
                        setValue={(value) => {
                            setApportionmentType(value)
                        }}
                        data={apportionmentOptions.map(opt => ({ ...opt, label: i18n.t(opt.label) }))}
                    />
                    <Filter
                        id="businessUnit-filter"
                        title={`${i18n.t("business_unit")} (${i18n.t("origin")})`}
                        type="radio"
                        hasSearch
                        hasSelectNone
                        searchPlaceholder={i18n.t("business_unit_search")}
                        value={businessUnit}
                        setValue={(value) => {
                            setBusinessUnit(value)
                            onChangeFlexFieldFilter(
                                value[0]?.value,
                                'BUSINESS_UNIT',
                                flexFieldsOriginOptions,
                                setFlexFieldsOriginOptions,
                                value[0]?.value,
                                costCenter[0]?.value ?? 0,
                                account[0]?.value ?? 0
                            )
                        }}
                        data={businessUnitOptions}
                    />
                    <Filter
                        id="costCenter-filter"
                        title={`${i18n.t("cost_center")} (${i18n.t("origin")})`}
                        type="radio"
                        hasSearch
                        hasSelectNone
                        searchPlaceholder={i18n.t("search_by_cost_center")}
                        value={costCenter}
                        setValue={(value) => {
                            setCostCenter(value)
                            onChangeFlexFieldFilter(
                                value[0]?.value,
                                'COST_CENTER',
                                flexFieldsOriginOptions,
                                setFlexFieldsOriginOptions,
                                businessUnit[0]?.value ?? 0,
                                value[0]?.value,
                                account[0]?.value ?? 0
                            )
                        }}
                        data={costCenterOptions}
                    />
                    <Filter
                        id="account-filter"
                        title={`${i18n.t("account")} (${i18n.t("origin")})`}
                        type="radio"
                        hasSearch
                        hasSelectNone
                        searchPlaceholder={i18n.t("search_by_cost_center")}
                        value={account}
                        setValue={(value) => {
                            setAccount(value)
                            onChangeFlexFieldFilter(
                                value[0]?.value,
                                'ACCOUNTING_ACCOUNT',
                                flexFieldsOriginOptions,
                                setFlexFieldsOriginOptions,
                                businessUnit[0]?.value ?? 0,
                                costCenter[0]?.value ?? 0,
                                value[0]?.value,
                            )
                        }}
                        data={optionsAccounting}
                    />
                    {flexFieldsOriginOptions.map(ffFilter => {
                        return (
                            <Filter
                                key={ffFilter.value}
                                id="flex-field-filter"
                                title={ffFilter.label}
                                type="radio"
                                value={ffFilter.selectedOption}
                                setValue={async (value: any) => {
                                    if (value.length > 0) {
                                        const flexString = await onChangeFlexFieldFilter(
                                            value[0]?.value,
                                            ffFilter.value,
                                            flexFieldsOriginOptions,
                                            setFlexFieldsOriginOptions,
                                            businessUnit[0]?.value ?? 0,
                                            costCenter[0]?.value ?? 0,
                                            account[0]?.value ?? 0
                                        )
                                        setFlexFieldStringKey(flexString)
                                    }
                                }}
                                data={ffFilter.children}
                                isFetching={ffFilter.isFetching ?? false}
                                hasSearch
                                hasSelectNone
                            />
                        )
                    })}
                </Carousel>
            }
            <div className="apportionment-apply-buttons">
                <Button
                    className='gs-main-button'
                    id="apportionment-filters-apply-button"
                    onClick={() => {
                        fetchApportionments(
                            type[apportionmentType[0]?.value] ?? ApportionmentType.ALL,
                            businessUnit[0]?.value ?? 0,
                            costCenter[0]?.value ?? 0,
                            account[0]?.value ?? 0,
                            flexFieldStringKey,
                            year[0].year()
                        )
                    }}
                    style={{ marginRight: '5px', marginLeft: '-100px' }}
                >
                    {i18n.t("apply")}
                </Button>
            </div>
            <div className="apportionment-import-buttons-container" >
                <ImportExportMenu
                    buttonType="3dots"
                    importProps={importProps}
                />
            </div>
        </div>
    )
}