import { Icon } from "@iconify/react";
import { Checkbox, Modal } from "antd";
import Search from "antd/lib/transfer/search";
import { useContext, useEffect, useState } from "react";

import { handleErrorRequest } from "util/functions/handleErrorRequest";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { ErrorRequest } from "util/types/types";
import { ReportViewContext } from "../../context/ReportViewContext";
import { Category } from "./Category";
import i18n from "util/base/i18n";
import { Loading } from "components/loading/Loading";
import { Organization } from "../../../mainFlow/IRevenue";
import { FlexFieldValuesRevenue, IFlexFieldRevenue, IOptionLevel, OptionLevelType } from "../../IReportView";
import { FlexFieldValuesData } from "module/budget/pages/flexField/flexFieldValues/IFlexFieldValues";

export function FiltersModal() {
    const {
        consolidationSelected,
        isFilterModalOpen,
        closeFilterModal,
        selectedFlexFieldValues,
        setSelectedFlexFieldValues,
        setSelectedOrganizations,
        selectedOrganizations,
        setSelectedFlexFieldValueDetails,
        selectedFlexFieldValuesDetails,
        selectedFlexFieldOrganizationValues,
        setSelectedFlexFieldOrganizationValues
    } = useContext(ReportViewContext);

    const [allOrganizations, setAllOrganizations] = useState<Organization[]>([]);
    const [allFlexFields, setAllFlexFields] = useState<IOptionLevel[]>([]);
    const [isFlexFieldsLoaded, setIsFlexFieldsLoaded] = useState(false);
    const [isSelectAllOrganizationsEnabled, setIsSelectAllOrganizationsEnabled] = useState(false);
    const [isSelectAllFlexFieldsEnabled, setIsSelectAllFlexFieldsEnabled] = useState(false);
    const [activeOptionLevelIndex, setActiveOptionLevelIndex] = useState<number>(0);
    const [optionLevels, setOptionLevels] = useState<IOptionLevel[]>([
        {
            description: i18n.t<string>('organization'),
            type: OptionLevelType.ORGANIZATION
        },
    ]);
    const [filterText, setFilterText] = useState('');
    const [selectedTemporaryFlexFieldValues, setSelectedTemporaryFlexFieldValues] = useState([]);
    const [selectedTemporaryOrganizations, setSelectedTemporaryOrganizations] = useState([]);

    function getOrganizations() {
        ServiceCaller.doRequest(
            {
                url: `monolith/organization/list-organization?client={client}&user={user}&locale={locale}`,
                type: RequestType.GET,
            },
            (data: Organization[]) => {
                setAllOrganizations(data);
            },
            (err: ErrorRequest) => {
                handleErrorRequest(err);
            }
        );
    }

    function getRevenueFlexFields() {
        ServiceCaller.doRequest(
            {
                type: RequestType.GET,
                url: `/budget-base/flex-field/find-all-by-module?module=REVENUE`,
            },
            async (response: any) => {
                const mappedFlexFields = response.map(flexField => {
                    return { id: flexField.id, description: flexField.description, fieldCode: flexField.fieldCode };
                }).sort((a, b) => a.fieldCode.localeCompare(b.fieldCode));

                const flexFieldValueDetailsList: any[] = await handleFlexDetails();
                const allFlexFieldsValues: IOptionLevel[] = await getAllFlexFieldsValues(mappedFlexFields);

                setOptionLevels(prevState => [...prevState, ...allFlexFieldsValues, ...flexFieldValueDetailsList]);
                setAllFlexFields([...allFlexFieldsValues, ...flexFieldValueDetailsList]);
                setIsFlexFieldsLoaded(true);
            }
        );
    }

    function getFlexFieldValues(flexFieldId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            ServiceCaller.doRequest(
                {
                    type: RequestType.GET,
                    url: `/budget-base/flex-field-value?user={user}&flexFieldId=${flexFieldId}`,
                },
                (response: any) => {
                    resolve(response);
                }
            );
        });
    }

    async function handleFlexDetails(): Promise<any[]> {
        let data: any = await getFlexFieldsDetails();
        let flexFieldValueDetails: IOptionLevel[] = [];

        data.forEach((flex) => {
            flexFieldValueDetails.push({
                id: flex.id,
                description: flex.description,
                type: OptionLevelType.FLEX_FIELD,
                isDetail: true,
                values: flex.flexFieldValues.map((flexValue) => {
                    return {
                        id: flexValue.value,
                        description: flexValue.label,
                        isDetail: true
                    }
                })
            })
        })

        return flexFieldValueDetails;
    }

    function getAllFlexFieldsValues(flexFields: any[]): Promise<IOptionLevel[]> {
        return new Promise(async (resolve, reject) => {
            const allFlexFieldsValues: IOptionLevel[] = [];

            for (const flexField of flexFields) {
                const flexFieldValues = await getFlexFieldValues(flexField.id);

                allFlexFieldsValues.push({
                    id: flexField.id,
                    description: flexField.description,
                    type: OptionLevelType.FLEX_FIELD,
                    values: flexFieldValues
                });
            }

            resolve(allFlexFieldsValues);
        });
    }

    function getFlexFieldsDetails() {
        return new Promise((resolve, reject) => {
            ServiceCaller.doRequest({
                type: RequestType.GET,
                url: '/budget-base/flex-field/find-all-details?module=REVENUE'
            }, resolve, handleErrorRequest);
        })
    }

    function handleSelectOption(clickedOrganizationId: number) {
        const selectedOptionLevelIndex = optionLevels.findIndex((option, index) => index === clickedOrganizationId);

        setActiveOptionLevelIndex(selectedOptionLevelIndex);
        setFilterText('');

        if (optionLevels[selectedOptionLevelIndex].type === OptionLevelType.FLEX_FIELD) {
            if (isSelectAllFlexFieldsEnabled) {
                setIsSelectAllFlexFieldsEnabled(false);
            }
        }
    }

    function handleFlexFieldValuesCheckboxChange(id: number, isDetail: boolean) {
        if (isDetail) {
            setSelectedFlexFieldValueDetails((prevCheckedItems) => {
                if (prevCheckedItems.includes(id)) {
                    return prevCheckedItems.filter((item) => item !== id);
                } else {
                    return [...prevCheckedItems, id];
                }
            });
        } else {
            setSelectedTemporaryFlexFieldValues((prevCheckedItems) => {
                if (prevCheckedItems.includes(id)) {
                    return prevCheckedItems.filter((item) => item !== id);
                } else {
                    return [...prevCheckedItems, id];
                }
            });
        }
    }

    function handleOrganizationCheckboxChange(id: number) {
        setSelectedTemporaryOrganizations((prevCheckedItems) => {
            if (prevCheckedItems.includes(id)) {
                return prevCheckedItems.filter((item) => item !== id);
            } else {
                return [...prevCheckedItems, id];
            }
        });
    };

    function handleSelectAllOrganizations() {
        if (isSelectAllOrganizationsEnabled) {
            setSelectedTemporaryOrganizations([]);
            setIsSelectAllOrganizationsEnabled(false);
        } else {
            setIsSelectAllOrganizationsEnabled(prevState => !prevState);
            setSelectedTemporaryOrganizations(allOrganizations.map(organization => organization.id));
        }
    }

    function handleSelectAllFlexFieldsValues(flexField: any) {
        if (isSelectAllFlexFieldsEnabled) {
            setSelectedTemporaryFlexFieldValues(prevAllFlexFieldsValues => {
                const flexFieldValuesIdsFromSelectedFlexField = flexField.values
                    .map(flexFieldValue => flexFieldValue.id);
                const flexFieldValuesIdsFromAnotherFlexFields = prevAllFlexFieldsValues
                    .filter(value => !flexFieldValuesIdsFromSelectedFlexField.includes(value));

                return flexFieldValuesIdsFromAnotherFlexFields;
            });
            setIsSelectAllFlexFieldsEnabled(false);
        } else if (!isSelectAllFlexFieldsEnabled) {
            setIsSelectAllFlexFieldsEnabled(true);

            const flexFieldValuesIds = flexField.values.map(flexFieldValue => flexFieldValue.id);

            setSelectedTemporaryFlexFieldValues(prevValues => {
                const newValues = flexFieldValuesIds.filter(value => !prevValues.includes(value));
                return [...prevValues, ...newValues];
            });
        }
    }

    function filterBusinessUnits(event: React.ChangeEvent<HTMLInputElement>) {
        const text = event.target.value;
        setFilterText(text);
    }

    function filterFlexFieldsValues(event: React.ChangeEvent<HTMLInputElement>) {
        const text = event.target.value;
        setFilterText(text);
    }

    function handleOk() {
        const flexFieldsValues: FlexFieldValuesRevenue[] = [];
        const flexFields: IFlexFieldRevenue = {};
        const flexFieldsOrganization: number[] = [];

        allFlexFields.forEach(flexField => {
            flexField.values.forEach(flexFieldValue => {
                if (selectedTemporaryFlexFieldValues.includes(flexFieldValue.id)) {
                    flexFieldsValues.push({
                        ...flexFieldValue,
                        flexField: {
                            id: flexField.id,
                            description: flexField.description
                        }
                    })
                }
            });
        });

        flexFieldsValues.forEach(flexFieldValue => {
            const flexFieldId = flexFieldValue.flexField.id;
            const isOrganization: boolean = !!flexFieldValue.organizationFilters?.length;
            if (isOrganization) {
                flexFieldsOrganization.push(flexFieldValue.id);
            }

            if (flexFields[flexFieldId]) {
                flexFields[flexFieldId].push(flexFieldValue.id);
            } else {
                flexFields[flexFieldId] = [flexFieldValue.id];
            }
        });

        setSelectedOrganizations(selectedTemporaryOrganizations);
        setSelectedFlexFieldValues(flexFields);
        setSelectedFlexFieldOrganizationValues(flexFieldsOrganization);
        closeFilterModal();
    }

    function handleCancel() {
        closeFilterModal();
    }

    useEffect(() => {
        if (!consolidationSelected) {
            getOrganizations();
        }
        getRevenueFlexFields();
        getFlexFieldsDetails();
    }, []);

    useEffect(() => {
        getFlexFieldsDetails();
    }, [allFlexFields]);

    useEffect(() => {
        const arrayOfNumbers = Object.values(selectedFlexFieldValues)
            .reduce((acc, currentValue) => acc.concat(currentValue), []);

        setSelectedTemporaryFlexFieldValues([...arrayOfNumbers]);
        setSelectedTemporaryOrganizations([...selectedOrganizations]);
    }, []);

    return (
        <Modal
            title={i18n.t<string>('filter')}
            visible={isFilterModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            width={800}
            wrapClassName="attribute_parameterization-new-attribute-modal report_view_modal"
            okText={i18n.t<string>('apply_data')}
            cancelText={i18n.t<string>('cancel')}
            centered
            destroyOnClose
        >
            <div id="report_view_modal_container">
                {isFlexFieldsLoaded && (
                    <div>
                        <div id="report_view_modal_filters_description">
                            <div>
                                {optionLevels.map((option, index) => {

                                    if (consolidationSelected && option.type === OptionLevelType.ORGANIZATION) {
                                        return null;
                                    }
                                    if (!consolidationSelected && option.type === OptionLevelType.ORGANIZATION) {
                                        return (
                                            <Category
                                                id={index}
                                                key={index}
                                                description={option.description}
                                                selectedCheckboxesLength={selectedTemporaryOrganizations.length}
                                                onClick={handleSelectOption}
                                                activeOptionLevelIndex={activeOptionLevelIndex}
                                            />
                                        );
                                    }

                                    const selectedFlexFieldsValuesFromFlexField = option?.values
                                        .filter(flexFieldValue => selectedTemporaryFlexFieldValues.includes(flexFieldValue.id));

                                    return (
                                        <Category
                                            id={index}
                                            key={index}
                                            description={option.description}
                                            selectedCheckboxesLength={option.isDetail ? selectedFlexFieldValuesDetails.length : selectedFlexFieldsValuesFromFlexField.length}
                                            onClick={handleSelectOption}
                                            activeOptionLevelIndex={activeOptionLevelIndex}
                                        />
                                    )
                                })}
                            </div>
                        </div>

                        <div id="report_view_modal_filters_arrow">
                            <div id="report_view_modal_filters_arrow_icon_wrapper">
                                <Icon icon={'ic:baseline-greater-than'} fontSize={20} />
                            </div>
                        </div>

                        <div id="report_view_modal_filters_checkboxes">
                            <div>
                                {(optionLevels[activeOptionLevelIndex] && optionLevels[activeOptionLevelIndex].type === OptionLevelType.ORGANIZATION) && (
                                    <>
                                        <div style={{ background: '#F5F5F5', padding: '15px' }}>
                                            <Search
                                                placeholder={i18n.t<string>('search')}
                                                onChange={filterBusinessUnits}
                                            />

                                            {allOrganizations.length > 0 && (
                                                <Checkbox
                                                    checked={isSelectAllOrganizationsEnabled}
                                                    onChange={handleSelectAllOrganizations}
                                                    style={{
                                                        fontWeight: 600
                                                    }}
                                                >
                                                    {i18n.t<string>('select_all')}
                                                </Checkbox>
                                            )}
                                        </div>

                                        <div style={{ padding: '15px', display: 'flex', flexDirection: 'column' }}>
                                            {allOrganizations
                                                .filter(organization => {
                                                    if (filterText) {
                                                        return organization.name.toLowerCase().includes(filterText.toLowerCase());
                                                    }

                                                    return true;
                                                })
                                                .map(organization => {
                                                    return (
                                                        <Checkbox
                                                            key={organization.id}
                                                            value={organization.id}
                                                            checked={selectedTemporaryOrganizations.includes(organization.id)}
                                                            onChange={() => handleOrganizationCheckboxChange(organization.id)}
                                                            style={{
                                                                marginLeft: 0,
                                                                marginBottom: '10px'
                                                            }}
                                                        >
                                                            {organization.name}
                                                        </Checkbox>
                                                    );
                                                })}
                                        </div>
                                    </>
                                )}

                                {(optionLevels[activeOptionLevelIndex] && optionLevels[activeOptionLevelIndex].type !== OptionLevelType.ORGANIZATION && optionLevels[activeOptionLevelIndex].type !== OptionLevelType.BUSINESS_UNIT) && (
                                    <>
                                        <div style={{ background: '#F5F5F5', padding: '15px' }}>
                                            <Search
                                                placeholder={i18n.t<string>('search')}
                                                onChange={filterFlexFieldsValues}
                                            />

                                            {allFlexFields.length > 0 && (
                                                <Checkbox
                                                    checked={isSelectAllFlexFieldsEnabled}
                                                    onChange={() => handleSelectAllFlexFieldsValues(optionLevels[activeOptionLevelIndex])}
                                                    style={{
                                                        fontWeight: 600
                                                    }}
                                                >
                                                    {i18n.t<string>('select_all')}
                                                </Checkbox>
                                            )}
                                        </div>

                                        <div style={{ padding: '15px', display: 'flex', flexDirection: 'column' }}>
                                            {allFlexFields
                                                .filter(flexField => flexField.id === optionLevels[activeOptionLevelIndex].id)
                                                .map(flexField => flexField.values)
                                                .map(flexFieldValuesList => {
                                                    return flexFieldValuesList
                                                        .filter(flexFieldValue => {
                                                            if (filterText) {
                                                                return flexFieldValue.description.toLowerCase().includes(filterText.toLowerCase());
                                                            }

                                                            return true;
                                                        })
                                                        .map((flexFieldValue: any) => {
                                                            return (
                                                                <Checkbox
                                                                    key={flexFieldValue.id}
                                                                    value={flexFieldValue.id}
                                                                    checked={flexFieldValue.isDetail ? selectedFlexFieldValuesDetails.includes(flexFieldValue.id) : selectedTemporaryFlexFieldValues.includes(flexFieldValue.id)}
                                                                    onChange={() => handleFlexFieldValuesCheckboxChange(flexFieldValue.id, flexFieldValue.isDetail)}
                                                                    style={{
                                                                        marginLeft: 0,
                                                                        marginBottom: '10px'
                                                                    }}
                                                                >
                                                                    {flexFieldValue.externalCode ? flexFieldValue.externalCode + " - " + flexFieldValue.description : flexFieldValue.description}
                                                                </Checkbox>
                                                            );
                                                        })
                                                })}
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                )}

                <div>
                    {!isFlexFieldsLoaded && (
                        <Loading />
                    )}
                </div>
            </div>
        </Modal>
    )
}
