import { Icon } from "@iconify/react";
import { Button, Dropdown, Menu } from "antd";
import { usePermissionContext } from "context/PermissionContext";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import i18n from "util/base/i18n";
import { MessageType, RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { IImportExportMenu, IImportModal } from "./IImportExportMenu";
import "./ImportExportMenu.sass";
import { ImportModal } from "./import/ImportModal";

export function ImportExportMenu({
    exportGridData,
    handleSuccessImport,
    importProps,
    buttonType,
    otherFeatures,
    iconStyles = { fontSize: 20, cursor: "pointer" },
}: IImportExportMenu) {
    const [importModalOpen, setImportModalOpen] = useState<boolean>(false);
    const [modalProps, setModalProps] = useState<IImportModal>();
    const [canExport, setCanExport] = useState<boolean>(false);
    const [canImport, setCanImport] = useState<boolean>(false);
    const { functionalityPermissions, isFetchingFuncPermissions } = usePermissionContext();
    const [finalUrl, setFinalUrl] = useState<string>(null);

    let importExportMenu = [];
    const btnType = buttonType ?? "3dots";

    const location = useLocation();
    const [pathName, setPathName] = useState<string>(null);

    useEffect(() => {
        if (location) {
            const tmp: string = location.pathname.slice(location.pathname.lastIndexOf("/") + 1);
            setPathName(tmp);
        }
    }, [location]);

    useEffect(() => {
        if (isFetchingFuncPermissions) return;
        setCanExport(functionalityPermissions.export ? functionalityPermissions.export : false);
        setCanImport(functionalityPermissions.import ? functionalityPermissions.import : false);

        if (pathName) {
            getDocumentationImport(pathName.replace(/-/g, ""));
        }
    }, [isFetchingFuncPermissions, functionalityPermissions]);

    if (importProps && canImport) {
        importExportMenu = importProps.map((item, index) => {
            return {
                key: index,
                label: (
                    <Button
                        className="grid-operation-importExport-btn"
                        onClick={() => {
                            setImportModalOpen(true);
                            setModalProps(item);
                        }}
                        type="text"
                        icon={<Icon icon="fa6-solid:file-import" />}
                    >
                        {item?.title ? i18n.t(item.title) : i18n.t("import")}
                    </Button>
                ),
                disabled: false,
            };
        });
    }

    if (exportGridData && canExport) {
        importExportMenu.push({
            key: "export",
            label: (
                <Button
                    className="grid-operation-importExport-btn"
                    onClick={exportGridData}
                    type="text"
                    icon={<Icon icon="fa6-solid:file-export" />}
                >
                    {i18n.t("export")}
                </Button>
            ),
        });
    }

    if (otherFeatures) {
        importExportMenu.push(...otherFeatures);
    }

    async function getDocumentationImport(pdfUrl: string) {
        ServiceCaller.doSendMessage(
            {
                url: `/applicationImprovements/doc`,
                messageType: MessageType.EXPORT_DOCUMENTATION_IMPORT,
                type: RequestType.POST,
                params: { finalUrl: pdfUrl },
                useProxy: false,
            },
            documentationUrl.bind(this)
        );
    }

    function documentationUrl(data: { finalUrl: string }): void {
        setFinalUrl(data.finalUrl !== "docNotFound" ? data.finalUrl : null);
    }

    return (
        <>
            <Dropdown overlay={<Menu items={importExportMenu} />} trigger={["click"]}>
                {btnType === "3dots" ? (
                    <Icon icon="mdi:dots-vertical" style={iconStyles} />
                ) : (
                    <Button
                        style={{
                            height: 35,
                            display: "flex",
                            alignItems: "center",
                            paddingRight: btnType === "buttonWithIcon" ? 8 : 15,
                            gap: 5,
                        }}
                    >
                        {i18n.t("import")}
                        {btnType === "buttonWithIcon" ? (
                            <Icon icon="tabler:chevron-down" style={{ marginBottom: -3 }} />
                        ) : null}
                    </Button>
                )}
            </Dropdown>
            {modalProps ? (
                <ImportModal
                    handleCancel={() => setImportModalOpen(false)}
                    importUrl={modalProps.importUrl}
                    isOpen={importModalOpen}
                    type={modalProps.type}
                    templateFileName={modalProps.templateFileName}
                    urlParams={modalProps.urlParams}
                    multiple={modalProps.multiple}
                    bodyData={modalProps.bodyData}
                    hasImportOption={modalProps.hasImportOption}
                    templateUrl={modalProps.templateUrl}
                    setImportModalOpen={setImportModalOpen}
                    finalUrl={finalUrl}
                    handleSuccessImport={handleSuccessImport}
					async={true}
                />
            ) : null}
        </>
    );
}
