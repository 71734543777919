import { useContext } from "react";
import { ReportViewContext } from "../../../context/ReportViewContext";
import { useUserContext } from "context/UserContext";
import i18n from "util/base/i18n";
import { Icon } from "@iconify/react";
import { Button } from "antd";
import DetailModal from "../components/detailModal/DetailModal";

export function Title() {

    const { userInfo } = useUserContext();

    const { consolidationSelected, scenarioToCompare, yearToCompare, periodBase, baseTarget, showRowFilterComparative, baseType, isDetailModalOpen, handleDetailModal } = useContext(ReportViewContext);

    return (
        <div id='report_view-title' style={{ display: !showRowFilterComparative ? 'flex' : null, justifyContent: !showRowFilterComparative ? 'flex-end' : null }}>
            {showRowFilterComparative && (
                <div style={{ display: 'flex', gap: 15 }}>
                    <span>
                        {i18n.t<string>("revenue.report_viewer.comparative")}: {scenarioToCompare.label} - {yearToCompare} - {i18n.t<string>(baseTarget)}
                    </span>
                    <span>
                        X
                    </span>
                    <span>
                        {i18n.t<string>("base")}: {userInfo.selection.scenarioName} - {periodBase && periodBase.length > 0 ? periodBase[0].format("YYYY") : ''} - {i18n.t<string>(baseType)}
                    </span>
                </div>
            )}
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {
                    !consolidationSelected && (
                        <Button
                            style={{
                                border: 'none',
                                boxShadow: 'none',
                                color: 'black',
                                background: 'none',
                            }}
                            icon={<Icon icon={'ri:menu-add-fill'} />}
                            onClick={() => handleDetailModal(true)}
                        />
                    )
                }

                {isDetailModalOpen && (
                    <DetailModal
                        isDetailModalOpen={isDetailModalOpen}
                        handleDetailModal={handleDetailModal}
                    />
                )}
            </div>
        </div>
    )
}