import { useEffect, useState, useCallback } from "react";
import i18n from "util/base/i18n";
import { ROOT_PATH } from "util/rootPath";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import { OpeningFilterRequest, OtherEventColumnRequest, OtherEventDataRequest, OtherEventDataResponse, OtherEventGridData as OtherEventDataRow, OtherEventRow, RowType } from "./IOtherEvents";
import { useOtherEventContext } from "./context/OtherEventContext";
import { Loading } from "components/loading/Loading";
import { Filters } from "./components/filters/Filters";
import { GridContainer } from "./components/gridContainer/GridContainer";
import { SummaryCards } from "./components/summaryCards/SummaryCards";
import { Error500 } from "components/error/Error500";
import { Notification } from "components/notification/Notification";
import { updateRowTotals } from "./components/gridContainer/functions/updateRowTotals";
import { invalidadeQuery } from "util/queryClient";
import EmptyList from "./components/gridContainer/components/EmptyList";
import { useBudgetDates } from "hooks/useBudgetDates";
import { ModuleType } from "util/types/types";

export function OtherEvents() {
    const [saveStatus, setSaveStatus] = useState({ isSaving: false, savedValue: false });

    const { data: budgetPeriodDates } = useBudgetDates(ModuleType.PLANNING);

    const {
        period,
        flexFieldStringKey,
        flexFieldsFilters,
        error500,
        setError500,
        userInfo,
        setOtherEventsCached,
        otherEventDataSource,
        setOtherEventGridData,
        onLoadOtherEventData,
        isFetchingGridData,
        setIsFetchingGridData,
        isLoadingCacheData,
        isFirstOpen,
        isFetchingFlexFields,
        currency,
        setIsLoadingCacheData,
        isQueryByPackage,
        currenciesSelected,
    } = useOtherEventContext();

    useEffect(() => {
        if (isFirstOpen) {
            setIsFetchingGridData(false);
            return;
        }
    }, [period, flexFieldStringKey]);

    function handleCreateOtherEvent() {
        setIsFetchingGridData(true);
        const openingFilterRequest: OpeningFilterRequest = {
            organizationId: userInfo?.selection?.organizationId,
            currencyId: userInfo?.currencyId,
            flexKey: flexFieldStringKey,
            scenarioId: userInfo?.selection?.scenarioId,
            userId: userInfo?.id,
            startDate: period[0].valueOf(),
            finalDate: period[1].valueOf(),
        };

        setOtherEventsCached(openingFilterRequest);
        ServiceCaller.doRequest(
            {
                type: RequestType.POST,
                url: "/planning/other-event",
                params: openingFilterRequest,
            },
            onLoadOtherEventData,
            () => setError500(true)
        );
    }

    function onClickNewBudgetOpening() {
        if (period[0] === null) {
            Notification({
                type: "warning",
                message: i18n.t("fixed_expense_texts.select_a_period_to_continue"),
            });
            return;
        }
        setIsLoadingCacheData(true);
        handleCreateOtherEvent();
    }

    const handleSavePlannedValue = useCallback(
        (request: OtherEventDataRequest, otherEventDataRow: OtherEventDataRow, previousValue: OtherEventDataRow) => {
			setSaveStatus({ isSaving: true, savedValue: false });
            request.currencyId = currenciesSelected[0].value;
            request.columns = request.columns.map((plannedMonth: any) => {

                return {
                    ...plannedMonth, currencyId: currenciesSelected[0].value
                }
            });

            ServiceCaller.doRequest(
                {
                    type: RequestType.POST,
                    url: "/planning/other-event/save-values",
                    params: [request],
                },
				(response: OtherEventDataResponse) => {
                    setSaveStatus({ isSaving: false, savedValue: true });
                    const updatedData = [...otherEventDataSource];
					invalidadeQuery(["summary-cards-fixed-expenses"]);
					response.rows.forEach((row: OtherEventRow) => {
						row.columns.forEach((column) => {
							const monthYear: number = column.monthYear;
							const monthYearFormated: string = Object.entries(otherEventDataRow.columns).find((item) => item[1].monthYear === monthYear)[0];
							otherEventDataRow.columns[monthYearFormated] = {
								...column,
								rate: otherEventDataRow.columns[monthYearFormated].currencyConversionValue,
							};
						});

						updateRowTotals(otherEventDataRow);
						const index: number = updatedData.findIndex(({ key }) => key === otherEventDataRow.key);
						updatedData.splice(index, 1, otherEventDataRow);
					});

                    setOtherEventGridData(updatedData);

                    setTimeout(() => {
                        setSaveStatus({ isSaving: false, savedValue: false });
                    }, 3000);
                },
                (err) => {
                    if (err.message && err.message === "expenses.cannot.change.while.import.data") {
                        Notification({
                            type: "warning",
                            message: i18n.t(err.message),
                        });
                        setOtherEventGridData(otherEventDataSource);
                    } else {
                        handleErrorRequest(err);
                    }
                    setSaveStatus({ isSaving: true, savedValue: false });
                }
            );
        },
        [otherEventDataSource, currenciesSelected, setOtherEventGridData]
    );

    if (error500) {
        return <Error500 />;
    }

    return (
        <>
            <div
                style={{ justifyContent: "space-between", height: 80, margin: "0px auto 16px auto", padding: "0 16px" }}
                className="page-title-content"
            >
                <h1>{i18n.t("planning_labels.other_events")}</h1>
                <SummaryCards savedValue={saveStatus.savedValue} isFirstOpen={isFirstOpen} />
                <div style={{ marginRight: "30px" }}>
                    <img
                        src={`${ROOT_PATH}/renderer/image/${currency?.image}`}
                        alt={currency?.isoCode}
                        style={{ marginRight: "5px", borderRadius: "50%", objectFit: "cover", width: 16, height: 16 }}
                    />
                    <label style={{ fontSize: "13px" }}>{currency?.isoCode}</label>
                </div>
            </div>
            <Filters/>
            {isLoadingCacheData || isFetchingFlexFields || isFetchingGridData ? (
                <div id="grid-container">
                    <Loading />
                </div>
            ) : (
                <section
                    style={{
                        height: "calc( 100vh - 180px)",
                        width: "100%",
                        backgroundColor: "#F1F2F3",
                        padding: "8px",
                    }}
                >
                    {isQueryByPackage || otherEventDataSource.length === 0 ? (
                        <EmptyList
                            onClickNewBudgetOpening={onClickNewBudgetOpening}
                            isSpendingPackage={otherEventDataSource.length > 0}
                        />
                    ) : (
                        <div id="df-grid-container">
                            <GridContainer
                                handleSavePlannedValue={handleSavePlannedValue}
                                saveStatus={saveStatus}
                                isFetching={isFetchingGridData}
                                setIsFetching={setIsFetchingGridData}
                                flexFieldsFilters={flexFieldsFilters}
                                budgetPeriodDates={budgetPeriodDates}
                            />
                        </div>
                    )}
                </section>
            )}
        </>
    );
}
