import { useEffect } from "react";
import { Button, DatePicker, Form, Radio, Select } from "antd";
import { selectProps } from "util/props/props";
import { ApportionmentType, IApportionmentForm } from "../../../IApportionment";
import { Icon } from "@iconify/react";
import i18n from "util/base/i18n";
import { updateFlexFieldFiltersValue } from "../../../util/updateFlexFieldFiltersValue";
import { onChangeFlexFieldValue } from "../../../util/onChangeFlexFieldValue";
import { Label } from "components/label/Label";
import { DefaultOptionType } from "antd/lib/cascader";

export function ApportionmentForm({
    handleSubmit,
    form,
    costCenterOptions,
    originAccountOptions,
    destinationAccountOptions,
    businessUnitOptions,
    isFetchingOriginAccountOptions,
    isFetchingDestinationAccountOptions,
    flexFieldsOriginOptions,
    flexFieldsDestinationOptions,
    setFlexFieldsDestinationOptions,
    setFlexFieldsOriginOptions,
    originCollapsed,
    setOriginCollapsed,
    destinationCollapsed,
    setDestinationCollapsed,
    apportionmentType,
    setApportionmentType,
    originTableData,
    destinationTableData,
    setIsTransitoryAccountOpen,
    setSelectedRowKeys,
    onChangeApportionmentType,
    openCalendarPeriod,
    isEditApportionment,
    setIsEditApportionment
}: IApportionmentForm) {
    const originCostCenter = Form.useWatch("origin-costCenter", form);
    const originAccount = Form.useWatch("origin-account", form);
    const originBusinessUnit = Form.useWatch("origin-businessUnit", form);
    const destinationCostCenter = Form.useWatch("destination-costCenter", form);
    const destinationAccount = Form.useWatch("destination-account", form);
    const destinationBusinessUnit = Form.useWatch("destination-businessUnit", form);

    const filter = (inputValue: string, path: DefaultOptionType[]) =>
        path.some(
            (option) => (option.label as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1,
        );

    useEffect(() => {
        form.setFieldValue("origin-account", null);
    }, [originCostCenter]);

    return (
        <section
            className="apportionment-form-section-container"
            style={{
                width:
                    originCollapsed && destinationCollapsed ? 120 : originCollapsed || destinationCollapsed ? 350 : 570,
            }}
        >
            <Form form={form} onFinish={handleSubmit} layout="vertical" requiredMark={false} name="apportionment-form">
                {!originCollapsed && !destinationCollapsed && originTableData.length === 0 && (
                    <div className="gs-flex align-center" style={{ padding: "8px 16px" }}>
                        <Form.Item
                            name="origin-year"
                            label={i18n.t<string>("year")}
                            rules={[{ required: true, message: i18n.t<string>("required_field") }]}
                            style={{ margin: "0 16px 0 0" }}
                        >
                            <DatePicker
                                className="gs-date-picker"
                                picker="year"
                                disabledDate={(current) =>
                                    !openCalendarPeriod.some((date) => date.year() === current.year())
                                }
                            />
                        </Form.Item>
                        <Label title={i18n.t<string>("mode")}>
                            <Radio.Group
                                className="gs-flex align-center flex-row"
                                style={{ padding: "8px 0px" }}
                                onChange={(e) => setApportionmentType(e.target.value)}
                                value={apportionmentType}
                            >
                                <Radio value={ApportionmentType.PERIOD}>{i18n.t<string>("by_period")}</Radio>
                                <Radio value={ApportionmentType.HEADCOUNT}>Headcount</Radio>
                            </Radio.Group>
                        </Label>
                    </div>
                )}
                <div className="apportionment-form-content">
                    <div
                        className={`origin-form-section ${originCollapsed ? "collapsed" : ""}`}
                        style={{ marginBottom: !originCollapsed || !destinationCollapsed ? 50 : 0 }}
                    >
                        {
                            originCollapsed ? (
                                <div className="gs-flex flex-col align-center" style={{ position: "fixed", left: 14 }}>
                                    <Icon
                                        icon="ph:arrow-left-bold"
                                        hFlip={true}
                                        onClick={() => {
                                            if (!isEditApportionment) {
                                                setOriginCollapsed(false);
                                                setIsTransitoryAccountOpen(false);
                                                setSelectedRowKeys([]);
                                            }
                                        }}
                                        style={{
                                            cursor: isEditApportionment ? "not-allowed" : "pointer",
                                            marginBottom: 20,
                                            opacity: isEditApportionment ? 0.5 : 1,
                                        }}
                                    />
                                    <p className="gs-tag blue" style={{ marginTop: 30, rotate: "-90deg " }}>
                                        {i18n.t<string>("origin")}
                                    </p>
                                </div>
                            ) : (
                                <>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                        <p className="gs-tag blue" style={{ margin: 0 }}>
                                            {i18n.t<string>("budget_source")}
                                        </p>
                                        {originTableData.length > 0 && (
                                            <Icon
                                                icon="ph:arrow-left-bold"
                                                onClick={() => setOriginCollapsed(true)}
                                                style={{ cursor: "pointer", marginBottom: 20 }}
                                            />
                                        )}
                                    </div>
                                    <div style={{ padding: 10 }}>
                                        <Form.Item
                                            name="origin-costCenter"
                                            label={i18n.t<string>("cost_center")}
                                            rules={[{ required: true, message: i18n.t<string>("required_field") }]}
                                        >
                                            <Select
                                                onChange={() =>
                                                    updateFlexFieldFiltersValue(
                                                        "COST_CENTER",
                                                        form,
                                                        "origin",
                                                        flexFieldsOriginOptions,
                                                        setFlexFieldsOriginOptions
                                                    )
                                                }
                                                options={costCenterOptions}
                                                {...selectProps}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name="origin-account"
                                            label={i18n.t<string>("accounting_account")}
                                            rules={[{ required: true, message: i18n.t<string>("required_field") }]}
                                        >
                                            <Select
                                                onChange={() =>
                                                    updateFlexFieldFiltersValue(
                                                        "ACCOUNTING_ACCOUNT",
                                                        form,
                                                        "origin",
                                                        flexFieldsOriginOptions,
                                                        setFlexFieldsOriginOptions
                                                    )
                                                }
                                                options={originAccountOptions}
                                                {...selectProps}
                                                loading={isFetchingOriginAccountOptions}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name="origin-businessUnit"
                                            label={i18n.t<string>("business_unit")}
                                            rules={[{ required: true, message: i18n.t<string>("required_field") }]}
                                        >
                                            <Select
                                                onChange={() =>
                                                    updateFlexFieldFiltersValue(
                                                        "BUSINESS_UNIT",
                                                        form,
                                                        "origin",
                                                        flexFieldsOriginOptions,
                                                        setFlexFieldsOriginOptions
                                                    )
                                                }
                                                options={businessUnitOptions}
                                                {...selectProps}
                                            />
                                        </Form.Item>

                                        {flexFieldsOriginOptions.length > 0 && (
                                            <p className="gs-tag blue">{i18n.t<string>("flexible_field")}</p>
                                        )}
                                    </div>
                                    <div className="flex-field-container">
                                        {flexFieldsOriginOptions.map((ff) => {
                                            return (
                                                <Form.Item
                                                    key={`origin-ff-${ff.value}`}
                                                    name={`origin-ff-${ff.value}`}
                                                    label={ff.label}
                                                >
                                                    <Select
                                                        onChange={(value) =>
                                                            onChangeFlexFieldValue(
                                                                value,
                                                                ff.value,
                                                                "origin",
                                                                originAccount,
                                                                originCostCenter,
                                                                originBusinessUnit,
                                                                flexFieldsOriginOptions,
                                                                setFlexFieldsOriginOptions,
                                                                form
                                                            )
                                                        }
                                                        options={ff.children}
                                                        {...selectProps}
                                                        allowClear
                                                    />
                                                </Form.Item>
                                            );
                                        })}
                                    </div>
                                    {!originCollapsed && originTableData.length > 0 && (
                                        <Radio.Group
                                            className="gs-flex align-start"
                                            style={{ padding: 10, marginBottom: 80 }}
                                            onChange={(e) => onChangeApportionmentType(e.target.value)}
                                            value={apportionmentType}
                                        >
                                            <Radio value={ApportionmentType.PERIOD}>{i18n.t<string>("by_period")}</Radio>
                                            <Radio value={ApportionmentType.HEADCOUNT}>Headcount</Radio>
                                        </Radio.Group>
                                    )}
                                </>
                            )}
                    </div>
                    <div
                        className={`destination-form-section ${destinationCollapsed ? "collapsed" : ""}`}
                        style={{ marginBottom: !originCollapsed || !destinationCollapsed ? 50 : 0 }}
                    >
                        {destinationCollapsed ? (
                            <div
                                className="gs-flex flex-col align-center jc-sb"
                                style={{ position: "fixed", left: originCollapsed ? 74 : 298 }}
                            >
                                <Icon
                                    icon="ph:arrow-left-bold"
                                    hFlip={true}
                                    onClick={() => setDestinationCollapsed(false)}
                                    style={{ cursor: "pointer", marginBottom: 20 }}
                                />
                                <p className="gs-tag blue" style={{ marginTop: 30, rotate: "-90deg " }}>
                                    {i18n.t<string>("destination")}
                                </p>
                            </div>
                        ) : (
                            <>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <p className="gs-tag blue" style={{ margin: 0 }}>
                                        {i18n.t<string>("selected_destination")}
                                    </p>
                                    {originTableData.length > 0 && (
                                        <Icon
                                            icon="ph:arrow-left-bold"
                                            onClick={() => setDestinationCollapsed(true)}
                                            style={{ cursor: "pointer", marginBottom: 20 }}
                                        />
                                    )}
                                </div>
                                <div style={{ padding: 10 }}>
                                    <Form.Item
                                        name="destination-costCenter"
                                        label={i18n.t<string>("cost_center")}
                                        rules={[{ required: true, message: i18n.t<string>("required_field") }]}
                                    >
                                        <Select
                                            mode="multiple"
                                            options={costCenterOptions}
                                            onChange={() =>
                                                updateFlexFieldFiltersValue(
                                                    "COST_CENTER",
                                                    form,
                                                    "destination",
                                                    flexFieldsDestinationOptions,
                                                    setFlexFieldsDestinationOptions
                                                )
                                            }
                                            {...selectProps}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="destination-account"
                                        label={i18n.t<string>("accounting_account")}
                                        rules={[{ required: true, message: i18n.t<string>("required_field") }]}
                                    >
                                        <Select
                                            onChange={() =>
                                                updateFlexFieldFiltersValue(
                                                    "ACCOUNTING_ACCOUNT",
                                                    form,
                                                    "destination",
                                                    flexFieldsDestinationOptions,
                                                    setFlexFieldsDestinationOptions
                                                )
                                            }
                                            options={destinationAccountOptions}
                                            {...selectProps}
                                            loading={isFetchingDestinationAccountOptions}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="destination-businessUnit"
                                        label={i18n.t<string>("business_unit")}
                                        rules={[{ required: true, message: i18n.t<string>("required_field") }]}
                                    >
                                        <Select
                                            onChange={() =>
                                                updateFlexFieldFiltersValue(
                                                    "BUSINESS_UNIT",
                                                    form,
                                                    "destination",
                                                    flexFieldsDestinationOptions,
                                                    setFlexFieldsDestinationOptions
                                                )
                                            }
                                            options={businessUnitOptions}
                                            {...selectProps}
                                        />
                                    </Form.Item>

                                    {flexFieldsDestinationOptions && <p className="gs-tag blue">{i18n.t<string>("flexible_field")}</p>}
                                </div>
                                <div className="flex-field-container">
                                    {flexFieldsDestinationOptions.map((ff) => {
                                        return (
                                            <Form.Item
                                                key={ff.value}
                                                name={`destination-ff-${ff.value}`}
                                                label={ff.label}
                                            >
                                                <Select
                                                    onChange={(value) =>
                                                        onChangeFlexFieldValue(
                                                            value,
                                                            ff.value,
                                                            "destination",
                                                            destinationAccount,
                                                            destinationCostCenter,
                                                            destinationBusinessUnit,
                                                            flexFieldsDestinationOptions,
                                                            setFlexFieldsDestinationOptions,
                                                            form
                                                        )
                                                    }
                                                    options={ff.children}
                                                    {...selectProps}
                                                    allowClear
                                                />
                                            </Form.Item>
                                        );
                                    })}
                                </div>
                            </>
                        )}
                    </div>
                    {destinationCollapsed && originCollapsed ? null : (
                        <footer className="gs-flex align-center jc-end">
                            <Button className="gs-main-button" htmlType="submit">
                                {i18n.t("add")}
                            </Button>
                        </footer>
                    )}
                </div>
            </Form>
        </section>
    );
}
