import { createContext, useContext, useState } from "react";
import { FormulaItem } from "../../attributeParameterization/IAttributeParameterization";
import { Formula, LevelFilterPlanning, LevelReport, NewReportContextProps, Report, ReportType } from "../IRegistrationSalesReports";
import { OptionSelect } from "module/budget/pages/collaborators/linkAccounts/ILinkAccounts";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";
import { useUserContext } from "context/UserContext";

const NewReportContext = createContext<NewReportContextProps>(undefined);

export const NewReportProvider: React.FC = ({ children }) => {
    const [reportType, setReportType] = useState<ReportType>();
    const [report, setReport] = useState<Report>();
    const [selectedRowKey, setSelectedRowKey] = useState<number>();
    const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
    const [selectedRowFormulas, setSelectedRowFormulas] = useState<Formula[]>([]);
    const [selectedRowFormulasFormated, setSelectedRowFormulasFormated] = useState<FormulaItem[]>([]);
    const [selectedOptionsList, setSelectedOptionsList] = useState<string[]>([]);
    const [optionsForFormula, setOptionsForFormula] = useState<OptionSelect[]>([]);
    const [formula, setFormula] = useState<FormulaItem[]>(selectedRowFormulasFormated);
    const [allListFormula, setAllListFormula] = useState<Map<number, LevelReport>>(new Map<number, LevelReport>());
    const [updateOptionsRecursively, setUpdateOptionsRecursively] = useState<string>(undefined);
    const [isSavingReport, setIsSavingReport] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [planningCondition, setPlanningCondition] = useState<LevelFilterPlanning[]>([]);

    const { userInfo: { selection: { businessUnitId, organizationId } } } = useUserContext();

    const isPlanningReport = (type: ReportType = reportType): boolean => {
        return type === ReportType.PLANNING || type?.toString() === "PLANNING";
    };
    const resetReportType = () => setReportType(undefined);

    const onSaveReport = (reportSelected, description): Promise<Report> => {
        return new Promise((resolve) => {
            setIsSavingReport(true);
            ServiceCaller.doRequest(
                {
                    type: RequestType.POST,
                    url: "budget-report/report/createReport",
                    params: {
                        description,
                        organizationId,
                        businessUnitId,
                        reportType: reportSelected,
                    },
                },
                (data) => {
                    setReport(data);
                    setIsSavingReport(false);
                    resolve(data);
                }
            );
        });
    };

    return (
        <NewReportContext.Provider
            value={{
                reportType,
                setReportType,
                isPlanningReport,
                resetReportType,
                report,
                setReport,
                selectedRowKey,
                setSelectedRowKey,
                selectedRowKeys,
                setSelectedRowKeys,
                selectedRowFormulas,
                setSelectedRowFormulas,
                selectedRowFormulasFormated,
                setSelectedRowFormulasFormated,
                selectedOptionsList,
                setSelectedOptionsList,
                optionsForFormula,
                setOptionsForFormula,
                formula,
                setFormula,
                allListFormula,
                setAllListFormula,
                updateOptionsRecursively,
                setUpdateOptionsRecursively,
                onSaveReport,
                isSavingReport,
                isLoading,
                setIsLoading,
                planningCondition,
                setPlanningCondition
            }}
        >
            {children}
        </NewReportContext.Provider>
    );
};

export const useNewReportContext = () => {
    const newReportContext = useContext(NewReportContext);
    return newReportContext;
};
