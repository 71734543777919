import { Form, Input, Tooltip } from "antd";
import type { InputRef } from "antd";
import type { FormInstance } from "antd/es/form";
import "./GridContainer.sass";
import { createContext, Key, useContext, useEffect, useRef, useState, useMemo, memo, Children } from "react";
import {
    Column,
    EditableCellProps,
    EditableRowProps,
    IGrid,
    RowType,
    ViewModeSelected,
    OtherEventGridData,
    OtherEventDataRequest,
} from "../../IOtherEvents";
import moment from "moment";
import { GridOperations } from "./components/GridOperations";
import { DistributionModal } from "./components/distributionModal/DistributionModal";
import { useUserContext } from "context/UserContext";
import { useOtherEventContext } from "../../context/OtherEventContext";
import { exportGrid } from "./functions/exportGrid";
import { Grid } from "./components/Grid";
import { cloneDeep } from "lodash";
import { createGridColumns } from "./utils/createGridColumns";
import { Icon } from "@iconify/react";
import { usePermissionContext } from "context/PermissionContext";
import i18n from "util/base/i18n";
import { validateMonetaryInput } from "util/functions/validateKey";

export function GridContainer({
    saveStatus,
    handleSavePlannedValue,
    isFetching,
    setIsFetching,
    flexFieldsFilters,
    budgetPeriodDates,
}: IGrid) {
    const [columns, setColumns] = useState<Column[]>([]);
    const [monthAmount, setMonthAmount] = useState(0);
    const [checkedList, setCheckedList] = useState(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
    const [searchValue, setSearchValue] = useState("");
    const [isCurrencyButtonDisabled, setisCurrencyButtonDisabled] = useState(true);
    const [isOpenCurrenciesPopover, setIsOpenCurrenciesPopover] = useState(false);
    const [isOpenViewModePopover, setIsOpenViewModePopover] = useState(false);
    const EditableContext = createContext<FormInstance<any> | null>(null);
    const [isDistributionModalOpen, setIsDistributionModalOpen] = useState(false);
    const [isDistributionButtonDisabled, setIsDistributionButtonDisabled] = useState(true);

    const { userInfo } = useUserContext();
    const [viewModeSelected, setViewModeSelected] = useState<ViewModeSelected[]>([ViewModeSelected.PROJECTED]);
    const [viewModeChecked, setViewModeChecked] = useState([{ value: "projectedValue", label: i18n.t("adjusted") }]);
    const { functionalityPermissions } = usePermissionContext();
    const [isOutOfPeriod] = useState(false);
    const inputRefs = useRef({});
    const {
        otherEventDataSource,
        setOtherEventGridData,
        period,
        selectedRows,
        setSelectedRows
    } = useOtherEventContext();

    useEffect(() => {
        if (otherEventDataSource.length === 0) return;

        const monthArray = [];

        let currentDate = cloneDeep(period[0]);

        while (currentDate.isSameOrBefore(period[1], "month")) {
            monthArray.push(currentDate.format("YYYY-MM"));
            currentDate.add(1, "month");
        }

        if (Object.keys(otherEventDataSource[0].columns).length >= monthArray.length) {
            const { columnData, updatedMonthAmount } = createGridColumns(
                otherEventDataSource,
                viewModeSelected,
                monthArray,
                flexFieldsFilters,
                budgetPeriodDates
            );
            setColumns(columnData as any);
            setMonthAmount(updatedMonthAmount);
            setIsFetching(false);
        }
    }, [
        otherEventDataSource,
        viewModeSelected
    ]);

    function onViewModeCheckboxChange(values) {
        setViewModeChecked(values);
    }

    function handleToggleDisableActionButtons(
        currency = true,
        distribution = true
    ) {
        if (selectedRowKeys.length > 0) {
            setSelectedRowKeys([]);
        }
        if (selectedRows.length > 0) {
            setSelectedRows([]);
        }
        setisCurrencyButtonDisabled(currency);
        setIsDistributionButtonDisabled(distribution);
    }

    const EditableRow: React.FC<EditableRowProps> = memo(({ ...props }) => {
        const [form] = Form.useForm();
        return (
            <Form form={form} component={false}>
                <EditableContext.Provider value={form}>
                    <tr {...props} />
                </EditableContext.Provider>
            </Form>
        );
    });

    const EditableCell: React.FC<EditableCellProps> = memo(
        ({ title, editable, children, dataIndex, record, ...restProps }) => {
            const [editing, setEditing] = useState(false);
            const inputRef = useRef<InputRef>(null);
            const form = useContext(EditableContext)!;
            let month = "";
            let monthArray = [];
            let rowType = RowType.PLANNED;

            if (record && dataIndex) {
                month = dataIndex[1];
                monthArray = Object.keys(record.columns);
                rowType = record.rowType === RowType.DETAIL ? RowType.PLANNED : record.rowType;
            }

            useEffect(() => {
                if (editing && dataIndex !== "lastFlexFieldDescription") {
                    inputRef.current!.focus();
                    inputRef.current!.select();
                }
            }, [editing]);

            function handleReplicateValues(
                record: OtherEventGridData,
                currentMonth: string,
                value: number,
                direction: "left" | "right"
            ) {
                const updatedOtherEventGridData = cloneDeep(otherEventDataSource);

                let dataToSave: OtherEventDataRequest = {
                    scenarioId: userInfo.selection.scenarioId,
                    organizationId: userInfo.selection.organizationId,
                    currencyId: record.currencyId ? record.currencyId : 0,
                    flexKey: record.otherEvent.flexFieldFilter,
                    columns: []
                };

                Object.keys(record.columns).forEach((month) => {
                    if (direction === "right" &&
                        moment(month, "YYYY-MM").isAfter(moment(currentMonth, "YYYY-MM"))) {
                        const indexItemToUpdate: number = updatedOtherEventGridData.findIndex(({ key }) => key === record.key);
                        updatedOtherEventGridData[indexItemToUpdate].plannedTotal += value - updatedOtherEventGridData[indexItemToUpdate].columns[month].plannedValue;
                        updatedOtherEventGridData[indexItemToUpdate].columns[month].plannedValue = value;
                        dataToSave.columns.push(updatedOtherEventGridData[indexItemToUpdate].columns[month]);
                    }
                });

                setOtherEventGridData(updatedOtherEventGridData);
                handleSavePlannedValue(dataToSave, updatedOtherEventGridData[record.key], record);
            }

            const toggleEdit = () => {
                setEditing(!editing);
                if (dataIndex === "plannedTotal") {
                    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
                } else {
                    const valueKey = record.rowType;
                    form.setFieldsValue({ columns: { [month]: record.columns[month][valueKey] } });
                }
            };

            const save = async () => {
                try {
                    const fieldObject = form.getFieldsValue();
                    let isTotalCell = false;
                    let newValue = 0;
                    toggleEdit();

                    if (fieldObject.columns) {
                        newValue = fieldObject.columns[month]
                            ? Number(fieldObject.columns[month].toString().replace(",", "."))
                            : 0;
                        if (record.columns[month][rowType] === newValue) {
                            return;
                        }
                    } else {
                        newValue = fieldObject.plannedTotal
                            ? Number(fieldObject.plannedTotal.toString().replace(",", "."))
                            : 0;
                        if (record["plannedTotal"] === newValue) {
                            return;
                        }
                        isTotalCell = true;
                    }

                    let dataToSave: OtherEventDataRequest = {
                        scenarioId: userInfo.selection.scenarioId,
                        organizationId: userInfo.selection.organizationId,
                        currencyId: record.currencyId ? record.currencyId : 0,
                        flexKey: record.otherEvent.flexFieldFilter,
                        columns: []
                    };

                    const updatedBudget = cloneDeep(otherEventDataSource);
                    const rowIndex = updatedBudget.findIndex(
                        (item) => record.key === item.key
                    );
                    let gridRow = updatedBudget.find((item) => record.key === item.key);

                    if (isTotalCell) {
                        const totalToDistribute = newValue;
                        const amount = monthArray.length;
                        if (amount <= 0) return;
                        let valueToDistribute =
                            totalToDistribute < 0
                                ? Math.ceil(totalToDistribute / amount)
                                : Math.floor(totalToDistribute / amount);
                        const restValue = totalToDistribute % amount;
                        let hasRest = true;
                        monthArray.forEach((month) => {
                            if (hasRest) {
                                gridRow.columns[month].plannedValue = valueToDistribute + restValue;
                                hasRest = false;
                            } else {
                                gridRow.columns[month].plannedValue = valueToDistribute;
                            }
                            let plannedMonthToSave = gridRow.columns[month];
                            dataToSave.columns.push(plannedMonthToSave);
                        });
                        gridRow.plannedTotal = newValue;
                    } else {
                        gridRow.plannedTotal += newValue - gridRow.columns[month].plannedValue;
                        gridRow.columns[month].plannedValue = newValue;
                        dataToSave.columns.push(gridRow.columns[month]);
                    }

                    updatedBudget[rowIndex] = gridRow;
                    setOtherEventGridData(updatedBudget);
                    handleSavePlannedValue(dataToSave, gridRow, record);
                } catch (errInfo) {
                    console.log("Save failed:", errInfo);
                }
            };

            let childNode = children;

            if (!record) return <td {...restProps}>{childNode}</td>;

            if (editable) {
                childNode = editing && dataIndex !== "lastFlexFieldDescription" ? (
                    <Form.Item style={{ margin: 0, padding: "2px 4px" }} name={dataIndex}>
                        <Input
                            onKeyDown={(e) => {
                                if (e.key === "Tab") {
                                    e.preventDefault();
                                    let refKey = `${record.key}-${record.rowType}`;

                                    let nextCellIndex = "";
                                    const refIndex = inputRefs.current
                                        ? Object.keys(inputRefs.current).findIndex((key) => key === refKey)
                                        : null;

                                    if (month !== monthArray[monthArray.length - 1]) {
                                        nextCellIndex = moment(month, "YYYY-MM").add(1, "month").format("YYYY-MM");
                                    } else {
                                        refKey = Object.keys(inputRefs.current)[refIndex + 1];
                                        nextCellIndex = monthArray[0];
                                    }
                                    if (inputRefs.current[refKey][nextCellIndex] && refIndex > -1) {
                                        inputRefs.current[refKey][nextCellIndex].click();
                                    }
                                    return;
                                }
                                validateMonetaryInput(e, true);
                            }}
                            ref={inputRef}
                            onPressEnter={save}
                            onBlur={save}
                        />
                    </Form.Item>
                ) : (
                    <div className="gs-table-input-editable-cell">
                        <div
                            className="editable-cell-value-wrap"
                            onClick={toggleEdit}
                            ref={(el) => {
                                if (!inputRefs.current[`${record.key}-${record.rowType}`]) {
                                    inputRefs.current[`${record.key}-${record.rowType}`] = {};
                                }
                                inputRefs.current[`${record.key}-${record.rowType}`][month] = el;
                            }}
                        >
                            {children}
                        </div>
                        <div className="gs-table-replicate-buttons-container right">
                            {month !== monthArray[monthArray.length - 1] &&
                                dataIndex !== "plannedTotal" &&
                                dataIndex !== "lastFlexFieldDescription" && (
                                    <Tooltip title={i18n.t("replicate_to_next_months")}>
                                        <Icon
                                            onClick={() => {
                                                handleReplicateValues(
                                                    record,
                                                    month,
                                                    record.columns[month][rowType],
                                                    "right"
                                                );
                                            }}
                                            icon="material-symbols:content-copy"
                                            style={{
                                                marginLeft: "auto",
                                                boxShadow: "none",
                                                color: "#A6A7A7",
                                                background: "transparent",
                                            }}
                                        />
                                    </Tooltip>
                                )}
                        </div>
                    </div>
                );
            }

            return <td {...restProps}>{childNode}</td>;
        }
    );

    const rowSelection = useMemo(() => {
        return {
            selectedRowKeys,
            onChange: (selRowKeys: React.Key[], selectedRows: OtherEventGridData[]) => {
                const hasDetailRow = selectedRows.some((row) => row.rowType === RowType.DETAIL);
                const hasParentRow = selectedRows.some((row) => row.rowType !== RowType.DETAIL);

                if (isOutOfPeriod || selRowKeys.length === 0) {
                    handleToggleDisableActionButtons();
                } else if (selRowKeys.length === 1) {
                    handleToggleDisableActionButtons(true, true);
                } else if (selRowKeys.length > 1) {
                    handleToggleDisableActionButtons(true, false);
                } else if ((!hasDetailRow && !hasParentRow) || (hasDetailRow && hasParentRow)) {
                    setisCurrencyButtonDisabled(true);
                }
                setSelectedRowKeys(selRowKeys);
                setSelectedRows(selectedRows);
            },
            renderCell: (checked, record: OtherEventGridData, index, originNode) => {
                if (record.rowType === RowType.QUANTITY || record.rowType === RowType.UNITVALUE) {
                    return null;
                }
                return originNode;
            },
        };
    }, [selectedRows]);

    const components = useMemo(
        () => ({
            body: {
                row: (props) => <EditableRow {...props} handleSavePlannedValue={handleSavePlannedValue} />,
                cell: (props) => <EditableCell {...props} />,
            },
        }),
        [handleSavePlannedValue]
    );

	const cols = useMemo(
        () =>
            columns.map((col) => {
                if (!col.editable || !functionalityPermissions.edit) {
                    return col;
                }

                return {
                    ...col,
                    children: col.children?.map((child) => {
                        if (!child.editable || !functionalityPermissions.edit) {
                            return child;
                        } else {
                            return {
                                ...child,

                                onCell: (record) => {
                                    return {
                                        record,
                                        editable: child.title === i18n.t("budgeted") ? child.editable : false,
                                        dataIndex: child.dataIndex,
                                        title: col.title,
                                        align: "center",
                                    };
                                },
                            };
                        }
                    }),
                    onCell: (record) => {
                        return {
                            record,
                            editable: col.editable,
                            dataIndex: col.dataIndex,
                            title: col.title,
                            align: "center",
                        };
                    },
                };
            }),
        [columns, functionalityPermissions]
    );

    const filteredData = otherEventDataSource.filter((obj) =>
        obj.lastFlexFieldDescription.toLowerCase().includes(searchValue.toLowerCase())
    );

    return (
        <div id="df-grid-content">
            <GridOperations
                currencyId={checkedList}
                setCurrencyId={setCheckedList}
                isOpenViewModePopover={isOpenViewModePopover}
                setIsOpenViewModePopover={setIsOpenViewModePopover}
                isOpenCurPopover={isOpenCurrenciesPopover}
                setIsOpenCurPopover={setIsOpenCurrenciesPopover}
                isCurrencyBtnDisabled={isCurrencyButtonDisabled}
                userDefaultCurrency={userInfo.currencyId}
                saveStatus={saveStatus}
                setOpenDistributionModal={setIsDistributionModalOpen}
                isDistributionButtonDisabled={isDistributionButtonDisabled}
                onViewModeCheckboxChange={onViewModeCheckboxChange}
                viewModeSelected={viewModeSelected}
                setViewModeSelected={setViewModeSelected}
                setViewModeChecked={setViewModeChecked}
                viewModeChecked={viewModeChecked}
                handleExportGrid={() => exportGrid(columns, otherEventDataSource, viewModeSelected, selectedRows)}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                budgetPeriodDates={budgetPeriodDates}
            />
            <Grid
                isFetching={isFetching}
                monthAmount={monthAmount}
                viewModeSelected={viewModeSelected}
                components={components}
                cols={cols}
                otherEventGridData={filteredData}
                rowSelection={rowSelection}
            />

            <DistributionModal
                setModalOpen={setIsDistributionModalOpen}
                isOpen={isDistributionModalOpen}
                selectedAccount={selectedRows}
                handleSavePlannedValue={handleSavePlannedValue}
                handleDisableActionButtons={handleToggleDisableActionButtons}
            />
        </div>
    );
}
