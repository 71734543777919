import { Button, Col, Form, Row } from "antd";
import i18n from "util/base/i18n";
import { CustomSelect } from "../../revenue/attributeParameterization/components/custom/CustomSelect";
import { useDistributionRuleContext } from "../context/DistributionRuleContext";
import { ICustomSelectOptions } from "../../revenue/attributeParameterization/IAttributeParameterization";
import { FilterResultType, FilterType, IAccountLevelResponse, IAccountNomenclatureLink, IDistributionRuleFilter, IDistributionRuleResultFilter, IFilterFormValues, IOptionRuleFilter, IOptionRuleResult, OperationType } from "../IDistributionRule";
import { PlusCircleFilled } from "@ant-design/icons";
import { useWatch } from "antd/es/form/Form";
import { ReactElement, useEffect, useMemo, useState } from "react";
import { ServiceCaller } from "util/service/ServiceCaller";
import { useUserContext } from "context/UserContext";
import { RequestType } from "util/service/IServiceCaller";
import { MeasuringUnit } from "../../revenue/mainFlow/IRevenue";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import { EqualOp, NotEqualOp } from "../../revenue/attributeParameterization/components/operators/MathOperators";
import { LessThanEqualOp } from "module/budget/pages/collaborators/additionalFields/components/operators/MathOperators";
import { Condition } from "../../revenue/attributeParameterization/components/rules/Condition";
import { IndexerResponse } from "../../budgetProjection/IBudgetProjection";
import { FlexFieldData } from "../../flexField/IFlexField";
import { arrayMove, SortableContainer, SortableElement } from "react-sortable-hoc";

export default function DistributionRule() {
	const {
		selectedTab,
		ruleFilterOptions,
		isOptionsLoading,
		filtersForm,
		ruleConditionFilter,
		ruleConditionResult,
		setRuleConditionFilter,
		setRuleConditionResult,
		isModalOpen,
		ruleForEdit,
		setHasEdited
	} = useDistributionRuleContext();
	const { userInfo: { groupIds } } = useUserContext();
	const fieldId: string = useWatch("fieldId", filtersForm);
	const [isValueLoading, setIsValueLoading] = useState(false);
	const [valueOptions, setValueOptions] = useState<ICustomSelectOptions[]>([]);
	const [fieldOptions, setFieldOptions] = useState<(IOptionRuleFilter | IOptionRuleResult)[]>([]);
	const [isLoadingFiltersDefaultFields, setIsLoadingFiltersDefaultFields] = useState(true);

	const SortableList = SortableContainer(() => {
		return buildFilterWorkArea();
	});

	useEffect(() => {
		if (!isModalOpen) return;
		if (selectedTab === "filter") {
			const filterAddOptions: IOptionRuleFilter[] = [
				{
					value: `0-${FilterType.ACCOUNTING_ACCOUNT}`,
					filter: FilterType.ACCOUNTING_ACCOUNT,
					label: i18n.t("accounting_account"),
				},
				{
					value: `0-${FilterType.COST_CENTER}`,
					filter: FilterType.COST_CENTER,
					label: i18n.t("cost_center"),
				},
			];

			const fetchNomenclatureLinks = async () => {
				try {
					const [accountNomenclatures, costCenterNomenclatures, flexDetails] = await Promise.all([
						ServiceCaller.doAsyncRequest<IAccountNomenclatureLink[]>({
							url: "/budget-base/account-hierarchy-nomenclature",
							type: RequestType.GET,
						}),
						ServiceCaller.doAsyncRequest<IAccountNomenclatureLink[]>({
							url: "/budget-base/cost-center-hierarchy-nomenclature",
							type: RequestType.GET,
						}),
						ServiceCaller.doAsyncRequest<FlexFieldData[]>({
							url: "/budget-base/flex-field/find-all-details-without-module",
							type: RequestType.GET,
						}),
					]);

					const formattedAccounts: IOptionRuleFilter[] = accountNomenclatures.data.map(
						({ id, nomenclature }) => ({
							value: `${id}-${FilterType.ACCOUNT_LEVEL}`,
							filter: FilterType.ACCOUNT_LEVEL,
							label: nomenclature,
						})
					);
					const formattedCostCenters: IOptionRuleFilter[] = costCenterNomenclatures.data.map(
						({ id, nomenclature }) => ({
							value: `${id}-${FilterType.COST_CENTER_LEVEL}`,
							filter: FilterType.COST_CENTER_LEVEL,
							label: nomenclature,
						})
					);
					const formattedDetails: IOptionRuleFilter[] = flexDetails.data.map(
						({ id, description, externalCode }) => ({
							value: `${id}-${FilterType.FLEX_FIELD_DETAIL}`,
							filter: FilterType.FLEX_FIELD_DETAIL,
							label: `${externalCode} - ${description}`,
						}))

					setFieldOptions([
						...filterAddOptions,
						...ruleFilterOptions,
						...formattedAccounts,
						...formattedCostCenters,
						...formattedDetails
					]);
				}
				catch (error) {
					handleErrorRequest(error);
				}
			};

			fetchNomenclatureLinks().finally(() => setIsLoadingFiltersDefaultFields(false));
		} else {
			const filterAddOptions: IOptionRuleResult[] = [
				{
					value: `0-${FilterResultType.ACCOUNTING_ACCOUNT}`,
					filter: FilterResultType.ACCOUNTING_ACCOUNT,
					label: i18n.t("accounting_account"),
				},
				{
					value: `0-${FilterResultType.COST_CENTER}`,
					filter: FilterResultType.COST_CENTER,
					label: i18n.t("cost_center"),
				},
			].filter(({ filter }) => !ruleConditionResult.some((item) => item.result === filter));

			setFieldOptions([...filterAddOptions, ...ruleFilterOptions]);
		}
	}, [selectedTab, ruleFilterOptions, ruleConditionResult, isModalOpen]);


	useEffect(() => {
		if (!ruleForEdit) return;
		setRuleConditionFilter(ruleForEdit.filters.map((item) => {
			let fieldName: string = item.fieldName;
			const { type } = item;

			if ([FilterType.COST_CENTER, FilterType.ACCOUNTING_ACCOUNT].includes(FilterType[type])) {
				fieldName = getLabelByFilter[type];
			}

			return ({
				...item,
				fieldName,
				filter: type as FilterType
			})
		}));

		setRuleConditionResult(ruleForEdit.results.map((item) => {
			let fieldName: string = item.fieldName;
			const { type } = item;
			if (type !== FilterResultType.FLEX_FIELD) {
				fieldName = getLabelByFilterResult[type];
			}
			return ({
				...item,
				fieldName,
				result: type as FilterResultType
			})
		}));
	}, [ruleForEdit])

	useEffect(() => {
		resetFormFields();
	}, [selectedTab, isModalOpen])

	const operatorsOptions: ICustomSelectOptions[] = [
		{
			label: "Igual",
			value: OperationType.EQUALS
		},
		{
			label: "Diferente",
			value: OperationType.NOT_EQUALS
		}
	]

	const getLabelByFilter: Record<FilterType, string> = {
		FLEX_FIELD: "",
		COST_CENTER: i18n.t("cost_center"),
		ACCOUNTING_ACCOUNT: i18n.t("accounting_account"),
		ACCOUNT_LEVEL: "",
		COST_CENTER_LEVEL: "",
		FLEX_FIELD_DETAIL: "",
	}

	const getLabelByFilterResult: Record<FilterResultType, string> = {
		FLEX_FIELD: "",
		COST_CENTER: i18n.t("cost_center"),
		ACCOUNTING_ACCOUNT: i18n.t("accounting_account"),
	}

	const resetFormFields = () => {
		filtersForm.resetFields(["fieldId", "fieldValueId"]);
		filtersForm.setFieldValue("operation", OperationType.EQUALS);
	}

	const handleFinishForm = ({ fieldId, fieldValueId, operation }: IFilterFormValues) => {
		const [id, type] = fieldId.split("-");
		const fieldName = fieldOptions.find(({ value }) => value === fieldId)?.label;

		if (selectedTab === "filter") {
			const values: number[] = Array.isArray(fieldValueId) ? fieldValueId : [fieldValueId];
			setRuleConditionFilter((state) => {
				let currentOrdenation: number = state.length;
				const newConditions: IDistributionRuleFilter[] = values.map((valueId) => {
					const fieldValueName: string | undefined = valueOptions.find((valueItem) => valueItem.value === valueId)?.label;
					const newCondition: IDistributionRuleFilter = {
						fieldId: Number(id),
						fieldValueId: valueId,
						operation,
						filterOrder: currentOrdenation,
						filter: FilterType[type],
						fieldValueName: fieldValueName || "",
						fieldName,
					};

					currentOrdenation++;
					return newCondition;
				});
				return state.concat(newConditions);
			});
		} else {
			const fieldValueName: string | undefined = valueOptions.find((valueItem) => valueItem.value === fieldValueId)?.label;
			setRuleConditionResult((state) => {
				const newCondition: IDistributionRuleResultFilter = {
					fieldId: Number(id),
					fieldValueId: fieldValueId as number,
					operation,
					filterOrder: state.length,
					result: FilterResultType[type],
					fieldValueName: fieldValueName || "",
					fieldName,
				};
				return state.concat(newCondition);
			});
		}

		resetFormFields();
		setHasEdited(true);
	};

	const getFilterRequest: Record<FilterType, string> = {
		FLEX_FIELD: "/budget-base/flex-field-value?user={user}",
		COST_CENTER: "/monolith/cost-center?client={client}&locale={locale}&organization={organization}",
		ACCOUNTING_ACCOUNT: "/monolith/accountingaccount?locale={locale}&client={client}&organization={organization}",
		ACCOUNT_LEVEL: "/budget-base/account-hierarchy/find-level-by-nomenclature?",
		COST_CENTER_LEVEL: "/budget-base/cost-center-hierarchy/find-level-by-nomenclature?",
		FLEX_FIELD_DETAIL: "/budget-base/flex-field-value?user={user}",
	}

	const formatAccountLevels = (data: IAccountLevelResponse): ICustomSelectOptions[] => {
		const formattedData: ICustomSelectOptions[] = Object.keys(data).map((id) => {
			return ({
				label: data[id],
				value: Number(id)
			})
		})
		return formattedData;
	}

	const buildUrlFieldValue = (filterType: FilterType, id?: string): string => {
		let url: string = getFilterRequest[filterType];
		const isFlexField = [FilterType.FLEX_FIELD, FilterType.FLEX_FIELD_DETAIL].includes(filterType);
		const isLevelHierarchy = [FilterType.ACCOUNT_LEVEL, FilterType.COST_CENTER_LEVEL].includes(filterType);

		if (isFlexField) {
			url += `&flexFieldId=${Number(id)}&group=${groupIds}`;
		} else if (isLevelHierarchy) {
			url += `nomenclatureId=${Number(id)}`;
		}

		return url;
	}

	const formatGenericOptions = (
		data: (MeasuringUnit | IndexerResponse)[]
	): ICustomSelectOptions[] =>
		data.map((item) => {
			if ("name" in item) {
				return { label: item.name, value: item.id };
			}
			const label = item.externalCode ? `${item.externalCode} - ${item.description}` : item.description;
			return {
				label,
				value: item.id,
			};
		});

	const onChangeField = (value: string) => {
		setIsValueLoading(true);
		const [id, type] = value.split("-");
		let url: string = buildUrlFieldValue(FilterType[type], id);

		ServiceCaller.doRequest(
			{ url, type: RequestType.GET },
			(data: (MeasuringUnit | IndexerResponse)[] | IAccountLevelResponse) => {
				const isHierarchicalType = [FilterType.ACCOUNT_LEVEL, FilterType.COST_CENTER_LEVEL].includes(
					FilterType[type]
				);

				const formattedData = isHierarchicalType ?
					formatAccountLevels(data as IAccountLevelResponse)
					: formatGenericOptions(data as (MeasuringUnit | IndexerResponse)[]);

				setValueOptions(formattedData);
				setIsValueLoading(false);
			},
			handleErrorRequest
		);

		filtersForm.resetFields(["fieldValueId"]);
	};

	const getMathOperator: Record<OperationType, ReactElement> = {
		PLUS: <PlusCircleFilled />,
		LESS: <LessThanEqualOp />,
		EQUALS: <EqualOp />,
		NOT_EQUALS: <NotEqualOp />
	}

	const removeCondition = (index: number) => {
		if (selectedTab === "filter") {
			setRuleConditionFilter((prevConditions) => {
				const newConditions = [...prevConditions];
				newConditions.splice(index, 1);
				return newConditions;
			});
		} else {
			setRuleConditionResult((prevConditions) => {
				const newConditions = [...prevConditions];
				newConditions.splice(index, 1);
				return newConditions;
			});
		}

		setHasEdited(true);
	}

	const SortableItem = SortableElement(({ value, index }) => {
		return (
			<div key={index} className="attribute_parameterization-new-attribute-modal-formula-workarea-tag" >
				<Condition
					key={index}
					index={index}
					handleRemove={removeCondition}
				>
					<div className="attribute_parameterization-new-attribute-modal-rule-label">
						{value.fieldName}
					</div>

					{getMathOperator[value.operation]}

					<div className="attribute_parameterization-new-attribute-modal-rule-label">
						{value.fieldValueName}
					</div>
				</Condition>
			</div>
		)
	})

	const buildFilterWorkArea = (): JSX.Element => {
		return (
			<div className="attribute_parameterization-new-attribute-modal-formula-workarea"
				id="attribute_parameterization-new-attribute-modal-formula-workarea" >
				{
					selectedTab === "filter" ?
						ruleConditionFilter.map((condition, index) => {
							return <SortableItem key={index} value={condition} index={index} />
						}) :
						ruleConditionResult.map((condition, index) => {
							return <SortableItem key={index} value={condition} index={index} />
						})
				}
			</div>
		)
	}

	const onSortEnd = ({ oldIndex, newIndex }) => {
		if (selectedTab === "filter") {
			setRuleConditionFilter((items) => {
				const newCondition = arrayMove(items, oldIndex, newIndex).map((item, index) => ({ ...item, filterOrder: index }));
				return newCondition;
			})
		} else {
			setRuleConditionResult((items) => {
				const newCondition = arrayMove(items, oldIndex, newIndex).map((item, index) => ({ ...item, filterOrder: index }));
				return newCondition;
			})
		}

		setHasEdited(true);
	};


	return (
		<>
			<Form
				name="distribution-rule-form"
				form={filtersForm}
				onFinish={handleFinishForm}
				id="attribute_parameterization-new-rules-modal-rules-section"
				className="distribution-rule-form"
			>
				<Row gutter={15}>
					<Col span={10}>
						<Form.Item
							rules={[{ required: true, message: i18n.t<string>("required_field") }]}
							name="fieldId"
						>
							<CustomSelect
								id="fieldId"
								labelName={i18n.t<string>("field")}
								options={fieldOptions}
								loading={isOptionsLoading && isLoadingFiltersDefaultFields}
								onChange={onChangeField}
								hasSearch
							/>
						</Form.Item>
					</Col>
					<Col span={4}>
						<Form.Item
							rules={[{ required: true, message: i18n.t<string>("required_field") }]}
							name="operation"
							initialValue={OperationType.EQUALS}
						>
							<CustomSelect
								id="operation"
								labelName={i18n.t<string>("operations")}
								options={operatorsOptions}
								disabled={selectedTab === "result"}
							/>
						</Form.Item>
					</Col>
					<Col span={10}>
						<Form.Item
							rules={[{ required: true, message: i18n.t<string>("required_field") }]}
							name="fieldValueId"
						>
							<CustomSelect
								id="fieldValueId"
								hasSearch
								labelName={i18n.t<string>("value")}
								disabled={!fieldId || isValueLoading}
								options={valueOptions}
								loading={isValueLoading}
								{...selectedTab === "filter" && { mode: "multiple" }}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row>
					<Button
						type="text"
						htmlType="submit"
						icon={<PlusCircleFilled />}
						className={`gs-secondary-button`}
					>
						{i18n.t<string>("add")}
					</Button>
				</Row>
			</Form>
			<div className="attribute_parameterization-new-attribute-modal-rules-workarea">
				<SortableList
					distance={10}
					onSortEnd={onSortEnd}
					axis="xy"
					helperClass="sortable-content"
				/>
			</div>
		</>
	)
}