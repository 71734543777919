import i18n from 'util/base/i18n'
import './RegistrationSalesReports.sass'
import { TopButtons } from 'components/topButtons/TopButtons'
import { CustomButtons } from 'components/topButtons/ITopButtons'
import RegistrationSalesReportsTable from './components/RegistrationSalesReportsTable'
import { useEffect, useState } from 'react'
import { Icon } from '@iconify/react'
import NewReport from './components/NewReport/NewReport'
import { ServiceCaller } from 'util/service/ServiceCaller'
import { RequestType } from 'util/service/IServiceCaller'
import { ITableData, ReportType } from './IRegistrationSalesReports'
import { Notification } from 'components/notification/Notification'
import NewReportModal from './components/NewReportModal'
import { useNewReportContext } from './components/NewReportContext'

export default function RegistrationSalesReports() {
    const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
    const [isOnRewReport, setIsOnNewReport] = useState(false);
    const [idReportForEdit, setIdReportForEdit] = useState(0);
    const [isFetching, setIsFetching] = useState(true);
    const [tableData, setTableData] = useState<ITableData[]>([]);
    const [isEditingReport, setIsEditingReport] = useState(false);
    const [isNewReportModalOpen, setIsNewReportModalOpen] = useState(false);
    const { reportType, resetReportType, isSavingReport, report } = useNewReportContext();

    function handleNew() {
        resetReportType();
        setIsNewReportModalOpen(true);
    }

    function handleEdit(reportId) {
        setIsEditingReport(true);
        setIdReportForEdit(reportId);
        setIsOnNewReport(true);
    }

    function getTableData() {
        setIsFetching(true);
        setSelectedRowKeys([]);
        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: "/budget-report/report/list",
        }, onLoadData)
    }

    function onDuplicate() {
        ServiceCaller.doRequest({
            type: RequestType.PATCH,
            url: `/budget-report/report/duplicate/${selectedRowKeys[0]}`,
            params: {}
        }, getTableData)
    }

    function handleDelete() {
        setIsFetching(true);
        ServiceCaller.doRequest({
            type: RequestType.POST,
            url: `/budget-report/report/delete?ids=${selectedRowKeys}`,
            params: {}
        }, onDelete)
    }

    function onDelete() {
        Notification({
            message: i18n.t<string>("report_excluded_successfully"),
            type: 'success'
        })
        getTableData();
    }

    const copyButton: CustomButtons = {
        onClick: onDuplicate,
        icon: <Icon icon="ps:copy" />,
        className: "icon-copy",
        disabled: !selectedRowKeys.length
    }

    function onCancel() {
        setIsOnNewReport(false);
        setIdReportForEdit(0);
        setIsEditingReport(false);
    }

    function onLoadData(data: ITableData[]) {
        setTableData(data);
        setIsFetching(false);
    }

    useEffect(() => {
        if (isOnRewReport) return;
        getTableData();
    }, [isOnRewReport])

    useEffect(() => {
        if (!ReportType[reportType] || isSavingReport || isEditingReport) return;
        handleEdit(report.id);
    }, [reportType, isSavingReport]);

    return (
        <div className="registration-sales-page">
            <NewReportModal
                isNewReportModalOpen={isNewReportModalOpen}
                setIsNewReportModalOpen={setIsNewReportModalOpen}
            />
            {isOnRewReport ?
                <NewReport
                    idReportForEdit={idReportForEdit}
                    onCancel={onCancel}
                    isEditing={isEditingReport}
                />
                :
                <>
                    <div className='header-content'>
                        <div className="page-title-content">
                            <h1>{i18n.t<string>("create_report_structure")}</h1>
                        </div>
                        <TopButtons
                            mainButtonTitle={i18n.t<string>("new_report")}
                            handleNew={handleNew}
                            handleEdit={() => handleEdit(selectedRowKeys[0])}
                            handleDelete={handleDelete}
                            customButtons={[copyButton]}
                            isEditable={selectedRowKeys.length === 1}
                            isDeletable={!!selectedRowKeys.length}
                        />
                    </div>
                    <main>
                        <RegistrationSalesReportsTable
                            onChange={(keys) => setSelectedRowKeys(keys)}
                            selectedRowKeys={selectedRowKeys}
                            isFetching={isFetching}
                            setIsFetching={setIsFetching}
                            setTableData={setTableData}
                            tableData={tableData}
                        />
                    </main>
                </>

            }
        </div>
    )
}