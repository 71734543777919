import { Button, Popconfirm } from "antd";
import { OperationsSelect } from "module/budget/pages/revenue/attributeParameterization/components/operators/OperationsSelect";
import { useEffect } from "react";
import i18n from "util/base/i18n";
import { IFormulaContentProps } from "../../../../IRegistrationSalesReports";
import { useNewReportContext } from "../../../NewReportContext";
import MultipleFormulaArea from "./MultipleFormulaArea";

export default function FormulaContent({
	onSaveFormulas,
	setHasUnsavedChanges,
	onCancelChanges,
	formula,
	setFormula,
	onAcceptFormulaChanges,
	classNameForPulseButton,
	optionsForFormula
}: IFormulaContentProps) {
	const { selectedOptionsList, setSelectedOptionsList, setUpdateOptionsRecursively, isLoading, setIsLoading, planningCondition } = useNewReportContext();

	useEffect(() => {
		if (!planningCondition) return;

		setIsLoading(false);
	},[planningCondition, isLoading])

	function removeItem(index: number) {
        const removedItem = formula[index];
		setFormula((state) => {
			const updatedFormula = state.filter((_, itemIndex) => itemIndex !== index);
		
			const remainingKeys = updatedFormula.map((item) => item.key);
			setSelectedOptionsList(selectedOptionsList.filter((selected) => remainingKeys.includes(selected)));
		
			return updatedFormula;
		});

		if (removedItem.type === "attribute") {
			setUpdateOptionsRecursively(removedItem.key);
		}

		setHasUnsavedChanges(true);
    }

	function addOperationToFormula(selectedOperation: string) {
		if (
			selectedOperation === "left_parenthesis" ||
			selectedOperation === "right_parenthesis"
		) {
			setFormula([
				...formula,
				{ type: "operator", content: selectedOperation, key: `OPERATORS-${Math.random()}` },
			]);
			setHasUnsavedChanges(true);
		}

		if (selectedOperation === "value") {
			setFormula([
				...formula,
				{ type: "value", content: selectedOperation, key: `INFORMED_VALUE-${Math.random()}` },
			]);
			setHasUnsavedChanges(true);
			return;
		}

		if (selectedOperation !== "operator") {
			setFormula([
				...formula,
				{ type: "operator", content: selectedOperation, key: `OPERATORS-${Math.random()}` },
			]);
			setHasUnsavedChanges(true);
		}
	}

	function onClickCleanFormula() {
		setFormula([]);
		setSelectedOptionsList([]);
		onSaveFormulas([]);
	}

	return (
		<div id="attribute_parameterization-new-attribute-modal-formula" className="attribute_parameterization-new-attribute-modal">
			<div className="form-field">
				<OperationsSelect
					onChooseOperation={addOperationToFormula}
					hasValueOperator
				/>
			</div>
			<div className="visualization-formula">
				<div className="header-buttons">
					<h4>{i18n.t<string>("formula_view")}</h4>
					<Popconfirm
						placement="bottomLeft"
						overlayClassName="popconfirm-delete"
						title={i18n.t<string>("clear_formula_level")}
						onConfirm={onClickCleanFormula}
						okText={i18n.t<string>("yes")}
						cancelText={i18n.t<string>("cancel")}
						okButtonProps={{ danger: true, className: 'popconfirm-delete-button' }}
					>
						<Button type="text">
							{i18n.t("clean")}
						</Button>
					</Popconfirm>
				</div>

				<MultipleFormulaArea setFormula={setFormula} formula={formula} removeItem={removeItem} />

				<div className="footer-buttons">
					<Button type="text" onClick={onCancelChanges.bind(this)}>
						{i18n.t("cancel")}
					</Button>
					<Button
						onClick={onAcceptFormulaChanges}
						type="default"
						className={`gs-secondary-button ${classNameForPulseButton}`}
					>
						{i18n.t("save")}
					</Button>
				</div>
			</div>
		</div>
	)
}