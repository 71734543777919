import { cloneDeep } from "lodash";
import StringUtil from "./StringUtil";

export function paginationConfig(tableData = [], showSizeChanger = true, defaultPageSize = 20) {
    return { 
        showSizeChanger, 
        hideOnSinglePage: tableData.length < 10, 
        defaultPageSize,
        total: tableData.length, // Total de linhas
        showTotal: (total) => `${total} itens`, // Texto do total
    }
}

export const commomFilterTableData = (data: any[], searchValue: string, fields: string[]): any[] => {
	const clonedData: any[] = cloneDeep(data);
	const normalizedSearchValue: string = StringUtil.getWithoutSpecials(searchValue);

	return clonedData.filter(item => fields.some(field => StringUtil.getWithoutSpecials(item[field] || '').includes(normalizedSearchValue)));
};
