import { Icon } from "@iconify/react";
import { Button, Form, Modal, Radio, Upload, message } from "antd";
import { Notification } from "components/notification/Notification";
import { useUserContext } from "context/UserContext";
import i18n from "util/base/i18n";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import { MessageType, RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { ImportResponse } from "util/types/types";
import { IImportModal } from "../IImportExportMenu";

const { Dragger } = Upload;

export function ImportModal({
    isOpen,
    handleCancel,
    importUrl,
    templateFileName,
    multiple = false,
    type,
    urlParams,
    hasImportOption = false,
    setImportModalOpen,
    useProxy = true,
    templateUrl,
    uploadType,
    bodyData,
    finalUrl,
    handleSuccessImport,
	async
}: IImportModal) {
    const [form] = Form.useForm();
    const { userInfo } = useUserContext();

    const typeAccept: string =
        type === "excel" ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" : "pdf";

    function handleDownloadStandardWorksheet() {
        ServiceCaller.doSendMessage({
            messageType: MessageType.REQUEST_FILE,
            params: {
                url: templateUrl,
                useProxy: useProxy,
                fileName: templateFileName,
            },
        });
    }
	
	function createNotification(response){
		Notification({
            type: response.success ? "success" : "error",
            message: i18n.t(response.message),
        });		
	}

    function handleDownloadDocumentation() {
        const fileName: string = finalUrl.split("/").pop()?.split("?").shift();

        ServiceCaller.doSendMessage({
            messageType: MessageType.REQUEST_FILE,
            params: {
                url: finalUrl.replace(/^\/gesplan/, ""),
                fileName: fileName,
            },
        });
    }

    const handleUpload = (file) => {
        const isXLSX: boolean = file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        if (!isXLSX) {
            message.error(i18n.t("import_modal.xlsx_file"));
        }
        return isXLSX;
    };

    function handleSubmit(data) {
        if (!data.file) {
            Notification({
                type: "warning",
                message: i18n.t("import_modal.no_file_selected"),
            });
            return;
        }
        const formData = new FormData();

        formData.append("file", data.file.file.originFileObj);
        formData.append("fileName", data.file.file.name);
        formData.append("clientId", userInfo.clientId.toString());
        formData.append("userId", userInfo.id.toString());
        formData.append("localeId", userInfo.localeId.toString());
        formData.append("scenarioId", userInfo.selection.scenarioId.toString());
        formData.append("organizationId", userInfo.selection.organizationId.toString());
        formData.append("businessUnitId", userInfo.selection.businessUnitId.toString());

        if (hasImportOption) {
            formData.append("importType", data.importType);
        } else {
            formData.append("importType", "CREATE_UPDATE");
        }

        if (uploadType) {
            formData.append("uploadType", uploadType);
        }

        if (bodyData) {
            for (const [key, value] of bodyData.entries()) {
                formData.append(key, value);
            }
        }

        const serializedData = [...formData.entries()];

        setImportModalOpen(false);

        Notification({
            type: "info",
            message: i18n.t("import_modal.import_in_progress"),
        });

        const urlImport = importUrl.includes("projectionlease") ? importUrl :
            importUrl +
            "?clientId={client}&userId={user}&localeId={locale}&businessUnitId={businessUnit}&organizationId={organization}&scenarioId={scenario}&groupIds={groups}" +
            (data.importType ? "&importType=" + data.importType : "&importType=CREATE_UPDATE") +
            (urlParams ? urlParams : "");

        ServiceCaller.doRequest(
            {
                type: RequestType.POST,
                url: urlImport,
                useProxy: useProxy,
                params: serializedData,
                isFile: true,
            },
            (response: ImportResponse) => {
				if(!Object.is(async , true)){
					createNotification(response);
				}
				
                handleSuccessImport();
            },
            handleErrorRequest
        );
    }

    return (
        <Modal
            title={i18n.t("import_modal.import_data")}
            okText={i18n.t("import_modal.load_data")}
            okButtonProps={{
                htmlType: "submit",
                form: "import-form",
            }}
            visible={isOpen}
            onCancel={handleCancel}
            className="gs-modal"
            cancelText={i18n.t<string>("cancel")}
            centered
        >
            <p>
                {i18n.t("import_modal.select_your_spreadsheet")}
                <Button style={{ padding: "0 4px" }} type="link" onClick={handleDownloadStandardWorksheet}>
                    {i18n.t("import_modal.standard_worksheet")}
                </Button>
                {i18n.t("import_modal.to_fill")}
            </p>

            {finalUrl ? (
                <p>
                    {i18n.t("import_modal.to_view")}
                    <Button style={{ padding: "0 4px" }} type="link" onClick={handleDownloadDocumentation}>
                        {i18n.t("click_here")}
                    </Button>
                    .
                </p>
            ) : null}
            <Form
                form={form}
                name="import-form"
                onFinish={handleSubmit}
                layout="vertical"
                requiredMark={false}
                initialValues={{
                    importType: "CREATE_UPDATE",
                }}
            >
                <Form.Item name="file" style={{ height: 185 }}>
                    <Dragger
                        accept={typeAccept}
                        style={{ height: 185 }}
                        name="file"
                        multiple={multiple}
                        maxCount={1}
                        onDrop={(e) => {
                            e.preventDefault();
                        }}
                        customRequest={({ file, onSuccess }) => {
                            const isValid = handleUpload(file);
                            if (isValid) {
                                onSuccess(null, null);
                            } else {
                                onSuccess("error", null);
                            }
                        }}
                    >
                        <p style={{ margin: 0 }}>
                            <Icon icon="ph:upload-simple-duotone" style={{ fontSize: 24 }} />
                        </p>
                        <p className="ant-upload-text">{i18n.t("import_modal.drag_your_file_here")}</p>
                        <p className="ant-upload-hint">
                            {i18n.t("import_modal.file_type")} {type} {i18n.t("import_modal.accepted_files")}
                        </p>
                    </Dragger>
                </Form.Item>
                {hasImportOption ? (
                    <Form.Item name="importType" label={`${i18n.t("human_resources.import_type")}:`}>
                        <Radio.Group style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                            <Radio value="CREATE_UPDATE">{i18n.t("import_modal.inclusion_change")}</Radio>
                            <Radio value="WIPE_CREATE">{i18n.t("import_modal.zeroing")}</Radio>
                            <Radio value="EXCLUSION_CREATE">{i18n.t("import_modal.exclusion")}</Radio>
                        </Radio.Group>
                    </Form.Item>
                ) : null}
            </Form>
        </Modal>
    );
}
