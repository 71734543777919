import { Select, Space, Typography } from "antd";
import { useContext } from "react";
import i18n from "util/base/i18n";
import { ReportViewContext } from "../context/ReportViewContext";

export function Title() {

    const { consolidations, handleConsolidationSelected } = useContext(ReportViewContext);

    return (
        <div id='report_view-title'>
            <Space id="report_view-header-title" align="center">
                <Typography.Title level={3} className="gs-font-color-primary">
                    {i18n.t("reports")}
                </Typography.Title>
            </Space>

            {consolidations.length > 0 && (
                <div style={{ display: 'flex', gap: 10, paddingRight: 50 }}>
                    <div>
                        <p>{i18n.t("consolidated.report")}</p>
                    </div>
                    <div>
                        <Select
                            allowClear
                            style={{ width: 200 }}
                            onChange={handleConsolidationSelected}
                            options={consolidations}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}
