import moment from "moment";
import i18n from "util/base/i18n";

import { BudgetPeriodDates } from "module/budget/pages/fixedExpense/IFixedExpense";
import { ModuleType } from "util/types/types";
import { Divider, Popover } from "antd";
import "./PeriodBoardInfo.sass";
import { Icon } from "@iconify/react";
import { Label } from "components/label/Label";
import { CSSProperties } from "react";

interface IPeriodBoardInfo {
    budgetPeriodDates: BudgetPeriodDates;
    period: moment.Moment[];
    moduleType: ModuleType;
    styles?: CSSProperties;
}

export function PeriodBoardInfo({ budgetPeriodDates, period, moduleType, styles }: IPeriodBoardInfo) {
    if (!budgetPeriodDates?.period || budgetPeriodDates?.period.length === 0) {
        return null;
    }

    if (!period || !period[0] || !period[1]) {
        return null;
    }
    let currentDate = period[0].clone();

    const yearList = [];
    while (currentDate.isSameOrBefore(period[1])) {
        yearList.push(currentDate.format("YYYY"));
        currentDate.add(1, "year");
    }

    const listofYearsWithOpenBudget = budgetPeriodDates.period.map((period) => period.year);
    const yearWithoutBudgetOpenPeriod = yearList.filter((year) => !listofYearsWithOpenBudget.includes(Number(year)));

    const withoutBudgetOpenPeriodTag =
        yearWithoutBudgetOpenPeriod.length > 0 ? (
            <span className="gs-tag red" style={{ margin: 8 }}>
                {`Não há orçamento aberto para ${yearWithoutBudgetOpenPeriod.join(", ")}`}
            </span>
        ) : null;

    let openPeriod = [];
    let revisionPeriod = [];

    budgetPeriodDates.period
        .filter((period) => yearList.includes(period.year.toString()))
        .forEach((period, index, array) => {
            if (
                period[`${moduleType}OpeningStartDate`] &&
                period[`${moduleType}OpeningEndDate`] &&
                (moment(budgetPeriodDates.localDate)
                    .endOf("day")
                    .isBefore(moment(period[`${moduleType}OpeningStartDate`]).endOf("day")) ||
                    moment(budgetPeriodDates.localDate)
                        .endOf("day")
                        .isAfter(moment(period[`${moduleType}OpeningEndDate`]).endOf("day")))
            ) {
                if (period.revision.length > 0) {
                    const isOnSchedule = period.revision.some((revision) => {
                        return (
                            moment(budgetPeriodDates.localDate).isAfter(
                                moment(revision.reviewPeriodStartDate).startOf("day")
                            ) &&
                            moment(budgetPeriodDates.localDate).isBefore(
                                moment(revision.reviewPeriodEndDate).endOf("day")
                            )
                        );
                    });
                    if (!isOnSchedule) {
                        const content = (
                            <div className="revision-container">
                                <div key={"outOfPeriod"} style={{ flexDirection: "column" }}>
                                    <div className="gs-flex align-center">
                                        <Label styles={{ margin: 8 }} title={i18n.t("fiscal_year")}>
                                            <p>{period.year}</p>
                                        </Label>
                                        <Divider type="vertical" />
                                        <Label styles={{ margin: 8 }} title={i18n.t("typing_period")}>
                                            <p>
                                                {moment(period[`${moduleType}OpeningStartDate`]).format("DD/MM/YYYY")} -{" "}
                                                {moment(period[`${moduleType}OpeningEndDate`]).format("DD/MM/YYYY")}
                                            </p>
                                        </Label>
                                    </div>
                                </div>
                            </div>
                        );
                        openPeriod.push(content);
                    } else {
                        const content = (
                            <div id="revision-container">
                                <div className="gs-flex flex-col align-start" style={{ marginLeft: 8 }}>
                                    {period.revision
                                        .filter(
                                            (rev) =>
                                                moment(budgetPeriodDates.localDate).isAfter(
                                                    moment(rev.reviewPeriodStartDate).startOf("day")
                                                ) &&
                                                moment(budgetPeriodDates.localDate).isBefore(
                                                    moment(rev.reviewPeriodEndDate).endOf("day")
                                                )
                                        )
                                        .map((rev) => {
                                            return (
                                                <div className="revision-content" key={rev.reviewPeriodEndDate}>
                                                    <Label styles={{ margin: 8 }} title={i18n.t("fiscal_year")}>
                                                        <p>{period.year}</p>
                                                    </Label>
                                                    <Divider type="vertical" style={{ marginRight: 16 }} />
                                                    <Label title={i18n.t("typing_period")}>
                                                        <p>{`${moment(rev.reviewPeriodStartDate).format(
                                                            "DD/MM/YYYY"
                                                        )} ${i18n.t("till")} ${moment(rev.reviewPeriodEndDate).format(
                                                            "DD/MM/YYYY"
                                                        )}`}</p>
                                                    </Label>
                                                    <Icon
                                                        icon="mingcute:arrow-left-line"
                                                        hFlip={true}
                                                        color="#0065b3"
                                                    />
                                                    <Label title={i18n.t("revised_period")}>
                                                        <p>{`${moment(rev.budgetStartDate).format("MM/YYYY")} ${i18n.t(
                                                            "till"
                                                        )} ${moment(rev.budgetEndDate).format("MM/YYYY")}`}</p>
                                                    </Label>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        );
                        revisionPeriod.push(content);
                    }
                } else {
                    const content = (
                        <div className="revision-container" key={"out-of-period"}>
                            <div key={"outOfPeriod"} style={{ flexDirection: "column" }}>
                                <div className="gs-flex align-center">
                                    <Label styles={{ margin: 8 }} title="Exercício">
                                        <p>{period.year}</p>
                                    </Label>
                                    <Divider type="vertical" />
                                    <Label styles={{ margin: 8 }} title={i18n.t("typing_period")}>
                                        <p>
                                            {moment(period[`${moduleType}OpeningStartDate`]).format("DD/MM/YYYY")} -{" "}
                                            {moment(period[`${moduleType}OpeningEndDate`]).format("DD/MM/YYYY")}
                                        </p>
                                    </Label>
                                </div>
                            </div>
                        </div>
                    );
                    openPeriod.push(content);
                }
            }
        });

    return (
        (openPeriod.length > 0 || revisionPeriod.length > 0 || withoutBudgetOpenPeriodTag) && (
            <div className="period-board-container" style={styles}>
                {openPeriod.length > 0 && (
                    <Popover content={openPeriod} placement="bottom">
                        <span className="gs-tag red">{i18n.t("fixed_expense_texts.outside_typing_period")}</span>
                    </Popover>
                )}
                {revisionPeriod.length > 0 && (
                    <Popover content={revisionPeriod} placement="bottom">
                        <span className="gs-tag yellow">{i18n.t("Em Revisão")}</span>
                    </Popover>
                )}
                {withoutBudgetOpenPeriodTag && withoutBudgetOpenPeriodTag}
            </div>
        )
    );
}
