import { Icon } from "@iconify/react";
import { Tooltip } from "antd";
import { BudgetPeriodDates } from "module/budget/pages/fixedExpense/IFixedExpense";
import moment from "moment";
import i18n from "util/base/i18n";
import ElementUtil from "util/ElementUtil";
import { formatNumber } from "util/formatNumber";
import { verifyBudgetPeriod } from "util/functions/verifyBudgetPeriod";
import { FlexFilter, ModuleType } from "util/types/types";
import { Column, OtherEventGridData, RowType, ViewModeSelected, totalSummary } from "../../../IOtherEvents";
import { createTableTotalsRow } from "../functions/createTableTotalsRow";

export function createGridColumns(
    otherEventGridData: OtherEventGridData[],
    viewModeSelected: ViewModeSelected[],
    monthArray: string[],
    flexFieldsFilters: FlexFilter[] = [],
    budgetPeriodDates: BudgetPeriodDates
) {
    let valuesColumn: Column[] = [];
	const updatedMonthAmount: number = monthArray.length;
	const totals: totalSummary[] = createTableTotalsRow(otherEventGridData, viewModeSelected);

    const descriptionWidth = otherEventGridData.reduce((acc, value, index) => {
        const width = Math.ceil(ElementUtil.getWidthOfText(value.lastFlexFieldDescription, "normal 14px"));
        if (width > acc) {
            return index === 0 ? width * 2 : width;
        }
        return acc;
    }, 0);

    monthArray.forEach((month) => {
        const momentMonth = moment(month, "YYYY-MM");

        let columnClassName = "";

        const currentBudgetPeriod = budgetPeriodDates.period.find(
            (budDate) => budDate.year === moment(month, "YYYY-MM").year()
        );
        const { isEditable, className } = verifyBudgetPeriod(
            currentBudgetPeriod,
            moment(momentMonth, "YYYY-MM"),
            budgetPeriodDates.localDate,
            ModuleType.PLANNING
        );

        if (budgetPeriodDates) {
            const budDate = budgetPeriodDates.period.find((budDate) => budDate.year === momentMonth.year());
            if (budDate) {
                if (
                    budDate.planningRealizedPeriod &&
                    momentMonth.isSameOrBefore(moment(budDate.planningRealizedPeriod).endOf("month"))
                ) {
                    columnClassName = className;
                }
            }
        }

        let monthTotal = totals.find((item) => item.month === month && item.order === 1);
        let monthTotalValue: number;

        if (monthTotal) {
            monthTotalValue = monthTotal.value;
        } else {
            monthTotalValue = 0;
        }

        const width = Math.ceil(
            ElementUtil.getWidthOfText(
                formatNumber(monthTotalValue, { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                "normal 14px"
            ) + 20
        );
        if (viewModeSelected.length > 0) {
            const titleMonthCell =
                momentMonth.format("MMM YYYY").charAt(0).toUpperCase() + momentMonth.format("MMM YYYY").slice(1);

            const viewColumn: Column[] = [
                {
                    title: i18n.t("budgeted"),
                    align: "center",
                    width: width > 75 ? width : 75,
                    className: columnClassName,
                    editable: isEditable,
                    dataIndex: ["columns", month],
                    render: (_, record: OtherEventGridData) => {
                        const plannedMonth = record.columns[month];
                        const value = plannedMonth.plannedValue;
                        return formatNumber(
                            value,
                            record.rowType !== RowType.QUANTITY && {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                            }
                        );
                    },
                },
            ];

            viewModeSelected.forEach((viewMode) => {
                const order = viewMode === "historicValue" ? 3 : 2;
                const monthTotalValue = totals.find((item) => item.month === month && item.order === order).value;
                const width = Math.ceil(
                    ElementUtil.getWidthOfText(
                        formatNumber(monthTotalValue, { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                        "normal 14px"
                    ) + 20
                );

                const title = viewMode === "historicValue" ? i18n.t("historic") : i18n.t("adjusted");
                viewColumn.push({
                    title,
                    align: "center",
                    className: columnClassName,
                    width: width > 75 ? width : 75,
                    editable: isEditable,
                    dataIndex: ["columns", month],
                    render: (_, record: OtherEventGridData) => {
                        const plannedMonth = record.columns[month];
                        return formatNumber(
                            plannedMonth[viewMode] ? plannedMonth[viewMode] : 0,
                            record.rowType !== RowType.QUANTITY && {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                            }
                        );
                    },
                });
            });

            valuesColumn.push({
                title: titleMonthCell,
                align: "center",
                className: columnClassName,
                editable: isEditable,
                dataIndex: ["columns", month],
                children: [...viewColumn],
            });
        } else {
            const titleMonthCell =
                momentMonth.format("MMM YYYY").charAt(0).toUpperCase() + momentMonth.format("MMM YYYY").slice(1);

            valuesColumn.push({
                editable: isEditable,
                title: titleMonthCell,
                className: columnClassName,
                align: "center",
                shouldCellUpdate(record, prevRecord) {
                    for (const [key, value] of Object.entries(record)) {
                        if (value !== prevRecord[key]) {
                            return true;
                        }
                    }
                    return false;
                },
                width: width > 75 ? width : 75,
                dataIndex: ["columns", month],
                render: (_, record: OtherEventGridData) => {
                    const plannedMonth = record.columns[month];
                    let value = plannedMonth && plannedMonth.plannedValue ? plannedMonth.plannedValue : 0;

                    return formatNumber(
                        value,
                        record.rowType !== RowType.QUANTITY && { maximumFractionDigits: 2, minimumFractionDigits: 2 }
                    );
                },
            });
        }
    });

    const columnData: Column[] = [
        {
            fixed: "left",
            width: descriptionWidth,
            title: flexFieldsFilters.length === 0 ? i18n.t("flexField.field_label") : flexFieldsFilters.at(-1).label,
            dataIndex: "lastFlexFieldDescription",
            align: "left",
            className: "table-first-column",
            editable: true,
        },
    ];

    columnData.push(...valuesColumn);

    let totalsColumns: Column[] = [];
    const monthTotalValue = totals.find((item) => item.order === 4).value;
    const width = Math.ceil(
        ElementUtil.getWidthOfText(
            formatNumber(monthTotalValue, { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
            "normal 14px"
        ) + 20
    );

    if (viewModeSelected.length > 0) {
        let totalsCol: Column[] = [
            {
                title: i18n.t("budgeted"),
                dataIndex: "plannedTotal",
                align: "center",
                fixed: "right",
                editable: true,
                width,
                render: (total, record: OtherEventGridData) => {
                    return formatNumber(
                        total,
                        record.rowType !== RowType.QUANTITY && { maximumFractionDigits: 2, minimumFractionDigits: 2 }
                    );
                },
            },
        ];

        viewModeSelected.forEach((viewMode) => {
            const order = viewMode === "historicValue" ? 3 : 7;
            const monthTotalValue = totals.find((item) => item.order === order)?.value;
            const width = Math.ceil(
                ElementUtil.getWidthOfText(
                    formatNumber(monthTotalValue, { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                    "normal 14px"
                ) + 20
            );

            const title = viewMode === "historicValue" ? i18n.t("historic") : i18n.t("adjusted");

            totalsCol.push({
                title,
                align: "center",
                fixed: "right",
                width: width > 75 ? width : 75,
                editable: false,
                dataIndex: viewMode.replace("Value", "Total"),
                render: (total, record: OtherEventGridData) => {
                    return formatNumber(
                        total,
                        record.rowType !== RowType.QUANTITY && { maximumFractionDigits: 2, minimumFractionDigits: 2 }
                    );
                },
            });
        });

        totalsColumns.push({
			title: i18n.t("total"), 
            align: "center",
            editable: true,
            dataIndex: "plannedTotal",
            children: [...totalsCol],
        });
        columnData.push(...totalsColumns);
    } else {
        columnData.push(
            {
                title: i18n.t("total"),
                dataIndex: "plannedTotal",
                align: "center",
                fixed: "right",
                editable: true,
                width,
                render: (text, record: OtherEventGridData) => {
                    if (record.rowType === RowType.QUANTITY || record.rowType === RowType.UNITVALUE) {
                        return null;
                    }

                    return formatNumber(text, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
                },
            }
        );
    }
    return { columnData, updatedMonthAmount };
}
