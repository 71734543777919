import { Icon } from "@iconify/react";
import { Button, Checkbox, Divider, Dropdown, Input, Popover, Space, Tooltip } from "antd";
import { Key, useContext, useEffect, useState } from "react";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { ErrorRequest, ModuleType } from "util/types/types";
import { handleExportGridData } from "util/functions/handleExportGridData";
import { ImportExportMenu } from "components/importExportMenu/ImportExportMenu";
import { IImportModal } from "components/importExportMenu/IImportExportMenu";
import { Notification } from "components/notification/Notification";
import { usePermissionContext } from "context/PermissionContext";
import { EyeOutlined } from "@ant-design/icons";
import { IHeaderBarGrid, RevenueItemTable } from "../../IRevenue";

import i18n from "util/base/i18n";
import CardFilterOrder from "../../components/CardFilterOrder";
import LoadingChanges from "./LoadingChanges";
import ViewModality from "./ViewModality";
import { RevenueContext } from "../../RevenueContext";
import CopyValuesModal from "./copyValuesModal/CopyValuesModal";
import { PeriodBoardInfo } from "module/budget/components/PeriodBoardInfo/PeriodBoardInfo";
import { useBudgetDates } from "hooks/useBudgetDates";
import { CalculateParams, Item, TranslationKey } from "../../../attributeParameterization/IAttributeParameterization";
import { useUserContext } from "context/UserContext";
import moment from "moment";

export default function HeaderBarGrid({
	itensOrdened, setItensOrdened,
	hasSavedValue,
	flexFieldTree, setFlexFieldTree,
	listRowsSelected, updateTreeGrid,
	columnsGrid,
	lastMonthAccomplished,
	setExpandedRowKeys,
}: IHeaderBarGrid) {

	const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

	const translations: TranslationKey[] = [
		{ label: i18n.t<string>("fixed_expense"), translationKey: "FIXED_EXPENSE" },
		{ label: i18n.t<string>("sale_deduction"), translationKey: "SALE_DEDUCTION" },
		{ label: i18n.t<string>("variable_expenses"), translationKey: "VARIABLE_EXPENSES" },
		{ label: i18n.t<string>("tribute"), translationKey: "TRIBUTE" },
		{ label: i18n.t<string>("marketing_cost_variable"), translationKey: "MARKETING_COST_VARIABLE" },
		{ label: i18n.t<string>("marketing_cost_fixed"), translationKey: "MARKETING_COST_FIXED" },
	];

	const handleSelectAttributesToCalc = (
		translationKey: string
	) => {
		setAttributesSelectedToCalc(
			attributesSelectedToCalc.includes(translationKey) ?
				attributesSelectedToCalc.filter(item => item !== translationKey) :
				[...attributesSelectedToCalc, translationKey]
		);
	};

	const [attributesSelectedToCalc, setAttributesSelectedToCalc] = useState<string[]>(
		translations.map(translation => translation.translationKey.toUpperCase())
	);

	const createItem = (key: string, labelText: string, translationKey: string): Item => ({
		key,
		label:
			<Checkbox
				checked={attributesSelectedToCalc.includes(translationKey)}
				onClick={() => handleSelectAttributesToCalc(translationKey.toUpperCase())}>
				<span>
					{labelText}
				</span>
			</Checkbox>,
		translationKey,
	});

	const generateItems = (translations: TranslationKey[]): Item[] => {
		return translations.map((translation, index) =>
			createItem((index + 1).toString(), translation.label, translation.translationKey)
		);
	};

	const attributes: Item[] = generateItems(translations);

	const [isCopyValuesModalOpen, setIsCopyValuesModalOpen] = useState(false);
	const [flexFieldTreeAux, setFlexFieldTreeAux] = useState<RevenueItemTable[]>([]);
	const [calculating, setCalculating] = useState(false);
	const [isExpandedRows, setIsExpandedRows] = useState<boolean>(false);
	const { functionalityPermissions } = usePermissionContext();
	const [isViewModalityOpen, setIsViewModalityOpen] = useState(false);
	const { data: budgetPeriodDates } = useBudgetDates(ModuleType.REVENUE)
	const [isCalculateDisabled, setIsCalculateDisabled] = useState<boolean>(true);

	const { userInfo } = useUserContext();

	const { isLoading, handleIsLoading, handleIsFetching, period, monthColumns } = useContext(RevenueContext);

	let listRowsToExport = [];

	const importProps: IImportModal[] = [
		functionalityPermissions.importbudget && {
			importUrl: "/revenue/planned/import",
			templateUrl: "/revenue/planned/import/template?user={user}&client={client}&locale={locale}",
			type: 'excel',
			title: i18n.t<string>("import_budget"),
			hasImportOption: false,
		},
		functionalityPermissions.importrealized && {
			importUrl: "/revenue/realized/import",
			templateUrl: "/revenue/realized/import/template?user={user}&client={client}&locale={locale}",
			type: 'excel',
			title: i18n.t<string>("import_accomplished")
		},
		functionalityPermissions.importstructure && {
			importUrl: "/revenue/structure/import",
			templateUrl: "/revenue/structure/import/template?user={user}&client={client}&locale={locale}",
			type: 'excel',
			title: i18n.t<string>("import_structure_revenue")
		}
	].filter(Boolean) as IImportModal[];

	useEffect(() => {
		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: "/revenue/grouper/attribute/check-status?client={client}&user={user}"
		}, setIsCalculateDisabled)
	}, [])
	
	function verifyUserPermissions(option: IImportModal) {
		if(option.importUrl === "/revenue/planned/import"){
			if(functionalityPermissions.excludeimport){
				option.hasImportOption = true;
			} else if(functionalityPermissions.zeroingimport){
				option.hasImportOption = true;	
			}			
		}
		
		return option;
	}

	function openViewModalityPopOver() {
		setIsViewModalityOpen(prevValue => !prevValue);
	}

	function handleExpandAllRows() {
		let keys: Key[] = getAllTreeRowKeys(flexFieldTree);

		setIsExpandedRows(true);
		setExpandedRowKeys(keys);
	}

	const getAllTreeRowKeys = (rows: RevenueItemTable[]): Key[] => {
		let keys: Key[] = [];

		rows.forEach(({ key, children }) => {
			if (children) {
				keys = keys.concat(getAllTreeRowKeys(children));
			}

			keys.push(key);
		});

		return keys;
	};

	function handleOpenCopyValuesModal() {
		setIsCopyValuesModalOpen(prevState => !prevState);
	}

	function exportGridExcel() {
		listRowsToExport = [];
		let values = getDatesWithValues(flexFieldTree);
		flattenObjects(values);
		let columnsToExport = JSON.parse(JSON.stringify(columnsGrid));

		columnsToExport.forEach((column, index) => {
			if (index === 0) {
				column.width = 200;
			} else {
				column.width = 90;
			}
		});

		handleExportGridData(listRowsToExport, columnsToExport, i18n.t<string>("revenues"));
	}

	function flattenObjects(list) {
		function getObjectInsideList(row, tabAmount: number = 1) {
			row.forEach(element => {
				element.length > 0 ? getObjectInsideList(element, tabAmount + 1) : listRowsToExport.push({
					...element,
					name: "    ".repeat(tabAmount) + element.name
				})
			});
		}

		list.forEach((a) => {
			a.length > 0 ? getObjectInsideList(a) : listRowsToExport.push(a);
		});
	}

	function getDatesWithValues(rows: RevenueItemTable[]) {
		let resultList = [];

		rows.forEach((row: RevenueItemTable) => {
			if (row.children && row.children.length > 0) {
				let rowToExcel = {
					name: row.name ? row.name : row.currencyName + " - " + row.measuringUnitName,
					total: row.total ? row.total : 0
				};

				monthColumns.forEach(month => {
					let actualRow = row.dates[month];
					if (actualRow && actualRow.total) {
						rowToExcel[month] = row.dates[month].total.value;
					}
				});

				if (!row.key.includes('lineBeforeCoin')) {
					resultList.push(rowToExcel);
				}

				resultList.push(getDatesWithValues(row.children));
			} else {
				let rowToExcel = {
					name: row.name ? row.name : row.currencyName + " - " + row.measuringUnitName,
					total: row.total ? row.total : 0
				};

				monthColumns.forEach(month => {
					let actualRow = row.dates[month];
					if (actualRow && actualRow.value || actualRow && actualRow.value === 0) {
						rowToExcel[month] = actualRow.value;
					} else if (actualRow && actualRow.total) {
						rowToExcel[month] = actualRow.total.value;
					}
				});

				if (!row.key.includes('lineBeforeCoin')) {
					resultList.push(rowToExcel);
				}
			}
		});

		return resultList;
	}

	const handleOpenChange = (flag: boolean) => {
		setIsDropdownOpen(flag);
	};

	function handleCalculate() {
		if (!calculating) {
			setCalculating(true);

			const [
				startPeriod,
				endPeriod,
			]: string[] = getPeriod();

			const params: CalculateParams = {
				groupers: attributesSelectedToCalc,
				startPeriod,
				endPeriod,
				clientId: userInfo.clientId,
				userId: userInfo.id,
				localeId: userInfo.localeId,
				scenarioId: userInfo.selection.scenarioId,
				organizationId: userInfo.selection.organizationId,
				businessUnitId: userInfo.selection.businessUnitId
			};

			ServiceCaller.doRequest(
				{
					type: RequestType.POST,
					url: `/revenue/grouper/attribute/calculate`,
					params: params,
				},
				(res: boolean) => {
					if (!res) {
						setCalculating(false);
						Notification({
							type: "success",
							message: i18n.t<string>("calculate_finished_successfully"),
							duration: 5,
						});
					} else {
						Notification({
							type: "info",
							message: i18n.t<string>("calculation_on_progress"),
							duration: 5,
						});
					}
				},
				(err: ErrorRequest) => {
					setCalculating(false);
					handleErrorRequest(err);
				}
			);
		}
	}

	const getPeriod = (): [string, string] => {
		const [
			startPeriodDate,
			endPeriodDate
		]: moment.Moment[] = period;

		if (startPeriodDate.isAfter(endPeriodDate)) {
			return [
				endPeriodDate.format("YYYY-MM") + "-01",
				startPeriodDate.format("YYYY-MM") + "-01"
			];
		} else {
			return [
				startPeriodDate.format("YYYY-MM") + "-01",
				endPeriodDate.format("YYYY-MM") + "-01"
			];
		}
	};

	const deleteGridLine = () => {
		handleIsLoading(true);
		const idsToDelete: number[] = listRowsSelected.map(({ id, ids }) => id || ids).filter(id => id).flat();

		if (idsToDelete.length > 0) {
			const idsQuery: string = idsToDelete.map(id => `id=${id}`).join("&");

			ServiceCaller.doRequest({
				url: `/revenue/field/value?${idsQuery}`,
				type: RequestType.DELETE
			}, updateTreeGrid, (err: ErrorRequest) => {
				handleErrorRequest(err);
				handleIsLoading(false);
			})
		} else {
			handleReload();
		}
	}

	function searchInTable(data, search, resultsNew) {
		if (!search && flexFieldTreeAux.length > 0) {
			setFlexFieldTree(flexFieldTreeAux);
			return;
		}

		if (flexFieldTreeAux.length === 0) {
			setFlexFieldTreeAux(flexFieldTree);
		}

		let results = [...resultsNew];
		for (let i = 0; i < data.length; i++) {
			const item: RevenueItemTable = data[i];
			if (item.children && item.children[0].fieldId) {
				searchInTable(item.children, search, results);
			} else {
				if (item.name && item.name.toLowerCase().includes(search.toLowerCase())) {
					results.push(item);
				}
				else if (item.children.find((row) => row.name.toLowerCase().includes(search.toLowerCase()))) {
					let childrens: RevenueItemTable[] = [];
					item.children.forEach(element => {
						if (element.name.toLowerCase().includes(search.toLowerCase())) {
							childrens.push(element);
						}
					})

					item.children = childrens;
					results.push(item);
				}
			}
			if (results.length > 0) {
				setFlexFieldTree(results);
			}
		}

		return results;
	}

	function handleReload() {
		handleIsFetching(true);
		updateTreeGrid(true);
	}

	function handleCloseExpandedRows() {
		setExpandedRowKeys([]);
		setIsExpandedRows(false);
	}

	return (
		<div id="revenue-table-header">
			
			<div style={{ display: 'flex', paddingLeft: 5 }}>
				{functionalityPermissions.expand && !isExpandedRows && (
					<Tooltip
						title={i18n.t<string>("expand_rows")}
						color={'#424242'}
					>
						<Button
							icon={<Icon icon={"material-symbols:expand"} />}
							style={{
								border: 0,
								background: 'none',
								outline: 'none',
								boxShadow: 'none',
								color: '#A6A7A7'
							}}
							onClick={handleExpandAllRows}
						/>
					</Tooltip>
				)}
				{functionalityPermissions.expand && isExpandedRows && (
					<Tooltip
						title={i18n.t<string>("minimize_rows")}
						color={'#424242'}
					>
						<Button
							icon={<Icon
								style={{
									fontSize: 19,
									marginTop: 4
								}}
								icon={"fluent:arrow-minimize-vertical-20-regular"} />}
							style={{
								border: 0,
								background: 'none',
								outline: 'none',
								boxShadow: 'none',
								color: '#A6A7A7'
							}}
							onClick={handleCloseExpandedRows}
						/>
					</Tooltip>
				)}
				<CardFilterOrder itensOrdened={itensOrdened} setItensOrdened={setItensOrdened} />

				{functionalityPermissions.calculate &&
					<Tooltip title={i18n.t<string>("calculate")} color={"#424242"}>
						<Dropdown menu={{ items: attributes }} placement="bottomLeft" open={isDropdownOpen} onOpenChange={handleOpenChange} dropdownRender={
							(menu) => (
								<div>
									{menu}
									<Divider style={{ margin: 0 }} />
									<Space style={{ padding: 8 }}>
										<Button
											type="primary"
											disabled={!attributesSelectedToCalc.length}
											onClick={() => handleCalculate()}
											size="small"
										>
											{i18n.t<string>("calculate")}
										</Button>
									</Space>
								</div>
							)
						}>
							<Button
								icon={<Icon icon="material-symbols:calculate-outline" />}
								style={{
									border: 0,
									background: "none",
									outline: "none",
									boxShadow: "none",
								}}>
							</Button>
						</Dropdown>
					</Tooltip>
				}
				
				{functionalityPermissions.updatevalues &&
					<Tooltip
						title={i18n.t<string>("reload_values")}
						color={'#424242'}
					>
						<Button
							icon={<Icon icon={"ci:arrow-reload-02"} />}
							style={{
								border: 0,
								background: 'none',
								outline: 'none',
								boxShadow: 'none',
								color: '#A6A7A7'
							}}
							onClick={handleReload}
						/>
					</Tooltip>
				}

				{functionalityPermissions.viewmode &&
					<Popover
						destroyTooltipOnHide
						visible={isViewModalityOpen}
						trigger={["click"]}
						onVisibleChange={openViewModalityPopOver}
						content={(
							<ViewModality setIsViewModalityOpen={setIsViewModalityOpen} />
						)}>
						<Tooltip placement="topLeft" title={"Modo de visualização"}>
							<Button style={{
								border: 'none', boxShadow: 'none', color: '#A6A7A7'
							}}
								icon={<EyeOutlined />}
							/>
						</Tooltip>
					</Popover>
				}

				{functionalityPermissions.valuecopy &&
					<Tooltip title={i18n.t<string>("copy_of_values")}>
						<Button
							style={{ border: 'none' }}
							onClick={handleOpenCopyValuesModal}
							icon={<Icon style={{ boxShadow: 'none', color: '#A6A7A7' }}
								icon={"fluent:copy-add-20-regular"} />}
						/>
						<CopyValuesModal
							isCopyValuesModalOpen={isCopyValuesModalOpen}
							handleCancel={handleOpenCopyValuesModal}
							setIsCopyValuesModalOpen={setIsCopyValuesModalOpen}
							updateTreeGrid={updateTreeGrid}
						/>
					</Tooltip>
				}

				{functionalityPermissions.remove && (
					<Tooltip placement="topLeft" title={i18n.t<string>("delete")}>
						<Button
							style={{ border: 'none' }}
							icon={<Icon style={{ boxShadow: 'none', color: '#A6A7A7' }}
								icon="cil:trash"
								onClick={deleteGridLine}></Icon>}>
						</Button>
					</Tooltip>
				)}
				{lastMonthAccomplished && moment.utc(lastMonthAccomplished).isValid() ? (
					<div id="label-period-filter">
						<div className="label-period-filter-text">
							{i18n.t<string>("accomplished") + ": " + lastMonthAccomplished}
						</div>
					</div>
				) : null}


				<PeriodBoardInfo
					budgetPeriodDates={budgetPeriodDates}
					moduleType={ModuleType.REVENUE}
					period={period}
				/>
			</div>

			<div style={{ display: 'flex', paddingRight: 5 }}>
				<div style={{
					marginRight: 10,
					paddingTop: 5
				}}>
					<LoadingChanges
						isLoading={isLoading}
						hasSavedValue={hasSavedValue}
					/>
				</div>
				<Input placeholder={i18n.t<string>("search_for_product")} onInput={(e) => searchInTable(flexFieldTree, e.currentTarget.value, [])} style={{ width: 200 }} />
				<div style={{
					paddingTop: 5
				}}>
					<ImportExportMenu
						exportGridData={() => exportGridExcel()}
						importProps={importProps.map(verifyUserPermissions)}
						buttonType="3dots"
					/>
				</div>
			</div>
		</div>
	)
}
